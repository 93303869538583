import React, { useState, useEffect } from 'react';
import style from './PopularprojectTemplate.module.css';
import project1 from '../../../../Images/project-1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faList, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { POPULARPROJECT } from './Popularproject.utils';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Backgroundanimation } from '../../Backgroundanimation';

const PopularprojectTemplate = () => {
    const navigate = useNavigate();
    const [popularProjects, setPopularProjects] = useState([]);
    const [cartData, setCartData] = useState([]);

    useEffect(() => {
        const fetchPopularProjects = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_WEBPORTAL_URL}/api/home`);
                setPopularProjects(response.data.ResponseData.template);
            } catch (error) {
                console.error('Error fetching popular project data:', error.message);
            }
        };

        fetchPopularProjects();
        fetchCartData(); // Fetch cart data when component mounts
    }, []);

    const fetchCartData = async () => {
        try {
            const userId = localStorage.getItem('userId');
            const response = await axios.get(`${process.env.REACT_APP_WEBPORTAL_URL}/api/cart-list/${userId}`);
            const data = response.data;

            if (data.ResponseCode === 1) {
                setCartData(data.ResponseData);
            } else {
                console.error('Failed to fetch cart data');
            }
        } catch (error) {
            console.error('Error fetching cart data:', error);
        }
    };

    const handleAddToCart = async (templateId) => {
        const userId = localStorage.getItem('userId');
        if (!userId) {
            navigate('/login');
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_WEBPORTAL_URL}/api/cart-add`, {
                user_id: userId,
                template_id: templateId,
            });
            console.log('Cart added successfully:', response.data);
            toast.success('Product added to cart successfully!');
            fetchCartData(); 
        } catch (error) {
            console.error('Error adding to cart:', error.message);
            toast.error('Failed to add product to cart!');
            return;
        }
        fetchCartData(); 
    };

    return (
        <>

            <section>
                {popularProjects && popularProjects.map((group) => (
                    <div className='mt-50' data-aos="fade-up" data-aos-duration="2000" key={group.id}>
                        <div className={style.popularFlex}>
                            <h2 className={style.headPopular}>{group.name}</h2>
                            <h3 className={style.subHeadPopular}>{group.description}</h3>
                        </div>
                        <Container fluid className='px-xxl-5'>
                            <div className='row' data-aos="fade-up" data-aos-duration="3000">
                                {group.feedwisetemplate && Object.values(group.feedwisetemplate).map((ele) => (
                                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 d-flex align-items-center mt-4' key={ele.id}>
                                        <div className={style.ProductGrid}>
                                            <div onClick={() => navigate(`/productdetails/${ele.id}`)} className={style.PopularImage}>
                                                <img src={ele.image} alt="" className={style.popularFlexImage} />
                                            </div>
                                            <div className='px-3 pt-4'>
                                                <div className='d-flex justify-content-between mb-5'>
                                                    <span className='fw-bold text-dark'>{ele.name}</span>
                                                    <span className='d-flex flex-column align-items-end fw-bold'>
                                                        ${ele.discount_price}
                                                        <span className='text-danger fw-bold text-decoration-line-through'>${ele.price}</span>
                                                    </span>
                                                </div>
                                                <hr />
                                                <hr />
                                                <div className='d-flex justify-content-between'>
                                                    <div className='d-flex align-items-center gap-1'>
                                                        <FontAwesomeIcon icon={faShoppingCart} />
                                                        <Button className='fw-bold btnGreen' onClick={() => handleAddToCart(ele.id)}>Add to cart</Button>
                                                    </div>
                                                    <div className='d-flex align-items-center gap-1'>
                                                        <FontAwesomeIcon icon={faList} />
                                                        <span className='fw-bold pointer' onClick={() => navigate(`/productdetails/${ele.id}`)}>Details</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Container>
                    </div>
                ))}
            </section>
            <ToastContainer />
        </>
    );
}

export default PopularprojectTemplate;
