import React from 'react'

const Backgroundanimation = () => {
  return (
  <div>
  &lt;&gt;
  {/*?xml version="1.0" encoding="utf-8"?*/}
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 3005.2 2056.7" style={{enableBackground: 'new 0 0 3005.2 2056.7'}} xmlSpace="preserve">
    <g id="Layer_2" className="st0">
      <rect x="0.6" className="st1" width="3004.1" height="2056.7" />
    </g>
    <g id="Layer_1">
      <g>
        <g>
          <g className="st2">
            <g>
              <path className="st3" d="M1295.4,99.7c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2l0,0
						C1297.7,94.5,1295.4,96.8,1295.4,99.7z" />
              <circle className="st3" cx="1300.6" cy="131.4" r="5.2" />
              <circle className="st3" cx="1268.9" cy="4.5" r="5.2" />
              <circle className="st3" cx="1268.9" cy="36.3" r="5.2" />
              <circle className="st3" cx="1268.9" cy={68} r="5.2" />
              <path className="st3" d="M1263.6,99.7c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2l0,0
						C1266,94.5,1263.6,96.8,1263.6,99.7z" />
              <circle className="st3" cx="1268.9" cy="131.4" r="5.2" />
              <path className="st3" d="M1263.6,163.2c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1266,157.9,1263.6,160.3,1263.6,163.2z" />
              <circle className="st3" cx="1268.9" cy="194.9" r="5.2" />
              <circle className="st3" cx="1237.2" cy="4.5" r="5.2" />
              <circle className="st3" cx="1237.2" cy="36.3" r="5.2" />
              <circle className="st3" cx="1237.2" cy={68} r="5.2" />
              <path className="st3" d="M1231.9,99.7c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2l0,0
						C1234.3,94.5,1231.9,96.8,1231.9,99.7z" />
              <circle className="st3" cx="1237.2" cy="131.4" r="5.2" />
              <path className="st3" d="M1231.9,163.2c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1234.3,157.9,1231.9,160.3,1231.9,163.2z" />
              <circle className="st3" cx="1237.2" cy="194.9" r="5.2" />
              <path className="st3" d="M1231.9,226.6c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1234.3,221.4,1231.9,223.7,1231.9,226.6z" />
              <circle className="st3" cx="1205.5" cy="4.5" r="5.2" />
              <circle className="st3" cx="1205.5" cy="36.3" r="5.2" />
              <circle className="st3" cx="1205.5" cy={68} r="5.2" />
              <path className="st3" d="M1200.2,99.7c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2l0,0
						C1202.5,94.5,1200.2,96.8,1200.2,99.7z" />
              <circle className="st3" cx="1205.5" cy="131.4" r="5.2" />
              <path className="st3" d="M1200.2,163.2c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1202.5,157.9,1200.2,160.3,1200.2,163.2z" />
              <circle className="st3" cx="1205.5" cy="194.9" r="5.2" />
              <path className="st3" d="M1200.2,226.6c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1202.5,221.4,1200.2,223.7,1200.2,226.6z" />
              <circle className="st3" cx="1205.5" cy="258.4" r="5.2" />
              <path className="st3" d="M1168.5,4.5c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1170.8-0.7,1168.5,1.6,1168.5,4.5C1168.5,4.5,1168.5,4.5,1168.5,4.5z" />
              <path className="st3" d="M1168.5,36.3c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2l0,0
						C1170.8,31,1168.5,33.4,1168.5,36.3z" />
              <path className="st3" d="M1168.5,68c0.1,2.9,2.5,5.2,5.4,5.1c2.9-0.1,5.2-2.5,5.1-5.4c-0.1-2.8-2.4-5.1-5.2-5.1
						c-2.9,0-5.2,2.3-5.2,5.2C1168.5,67.9,1168.5,67.9,1168.5,68z" />
              <path className="st3" d="M1168.5,99.7c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2l0,0
						C1170.8,94.5,1168.5,96.8,1168.5,99.7z" />
              <path className="st3" d="M1168.5,131.4c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2l0,0
						C1170.8,126.2,1168.5,128.5,1168.5,131.4z" />
              <path className="st3" d="M1168.5,163.2c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1170.8,157.9,1168.5,160.3,1168.5,163.2z" />
              <path className="st3" d="M1168.5,194.9c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1170.8,189.6,1168.5,192,1168.5,194.9z" />
              <path className="st3" d="M1168.5,226.6c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1170.8,221.4,1168.5,223.7,1168.5,226.6z" />
              <path className="st3" d="M1168.5,258.3c0,2.9,2.3,5.3,5.2,5.3c2.9,0,5.3-2.3,5.3-5.2c0-2.9-2.3-5.3-5.2-5.3c0,0,0,0,0,0
						C1170.8,253.1,1168.5,255.4,1168.5,258.3z" />
              <circle className="st3" cx={1142} cy="4.5" r="5.2" />
              <circle className="st3" cx={1142} cy="36.3" r="5.2" />
              <circle className="st3" cx={1142} cy={68} r="5.2" />
              <path className="st3" d="M1136.8,99.7c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2c-0.1,0-0.1,0-0.2,0
						C1139,94.6,1136.8,96.9,1136.8,99.7z" />
              <circle className="st3" cx={1142} cy="131.4" r="5.2" />
              <path className="st3" d="M1136.8,163.2c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2c-0.1,0-0.1,0-0.2,0
						C1139,158,1136.8,160.3,1136.8,163.2z" />
              <circle className="st3" cx={1142} cy="194.9" r="5.2" />
              <path className="st3" d="M1136.8,226.6c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2c-0.1,0-0.1,0-0.2,0
						C1139,221.5,1136.8,223.8,1136.8,226.6z" />
              <circle className="st3" cx={1142} cy="258.4" r="5.2" />
              <circle className="st3" cx={1142} cy="290.1" r="5.2" />
              <circle className="st3" cx="1110.3" cy="4.5" r="5.2" />
              <circle className="st3" cx="1110.3" cy="36.3" r="5.2" />
              <circle className="st3" cx="1110.3" cy={68} r="5.2" />
              <path className="st3" d="M1105,99.7c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2l0,0
						C1107.4,94.5,1105,96.8,1105,99.7z" />
              <circle className="st3" cx="1110.3" cy="131.4" r="5.2" />
              <path className="st3" d="M1105,163.2c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1107.4,157.9,1105,160.3,1105,163.2z" />
              <circle className="st3" cx="1110.3" cy="194.9" r="5.2" />
              <path className="st3" d="M1105,226.6c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1107.4,221.4,1105,223.7,1105,226.6z" />
              <circle className="st3" cx="1110.3" cy="258.4" r="5.2" />
              <circle className="st3" cx="1110.3" cy="290.1" r="5.2" />
              <circle className="st3" cx="1078.6" cy="4.5" r="5.2" />
              <circle className="st3" cx="1078.6" cy="36.3" r="5.2" />
              <circle className="st3" cx="1078.6" cy={68} r="5.2" />
              <path className="st3" d="M1073.3,99.7c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2l0,0
						C1075.7,94.5,1073.3,96.8,1073.3,99.7z" />
              <circle className="st3" cx="1078.6" cy="131.4" r="5.2" />
              <path className="st3" d="M1073.3,163.2c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1075.7,157.9,1073.3,160.3,1073.3,163.2z" />
              <circle className="st3" cx="1078.6" cy="194.9" r="5.2" />
              <path className="st3" d="M1073.3,226.6c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1075.7,221.4,1073.3,223.7,1073.3,226.6z" />
              <circle className="st3" cx="1078.6" cy="258.4" r="5.2" />
              <circle className="st3" cx="1078.6" cy="290.1" r="5.2" />
              <circle className="st3" cx="1046.9" cy="4.5" r="5.2" />
              <circle className="st3" cx="1046.9" cy="36.3" r="5.2" />
              <circle className="st3" cx="1046.9" cy={68} r="5.2" />
              <path className="st3" d="M1041.6,99.7c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2l0,0
						C1043.9,94.5,1041.6,96.8,1041.6,99.7z" />
              <circle className="st3" cx="1046.9" cy="131.4" r="5.2" />
              <path className="st3" d="M1041.6,163.2c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1043.9,157.9,1041.6,160.3,1041.6,163.2z" />
              <circle className="st3" cx="1046.9" cy="194.9" r="5.2" />
              <path className="st3" d="M1041.6,226.6c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
						C1043.9,221.4,1041.6,223.7,1041.6,226.6z" />
              <circle className="st3" cx="1046.9" cy="258.4" r="5.2" />
              <circle className="st3" cx="1046.9" cy="290.1" r="5.2" />
              <path className="st3" d="M1010,4.5c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2c0,0-0.1,0-0.1,0
						C1012.2-0.7,1010,1.7,1010,4.5z" />
              <path className="st3" d="M1010,36.3c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2S1010,33.4,1010,36.3
						C1010,36.3,1010,36.3,1010,36.3z" />
              <path className="st3" d="M1010,68c0.1,2.9,2.5,5.2,5.4,5.1c2.9-0.1,5.2-2.5,5.1-5.4c-0.1-2.9-2.5-5.2-5.4-5.1
						C1012.2,62.7,1009.9,65.1,1010,68C1010,68,1010,68,1010,68z" />
              <path className="st3" d="M1010,99.7c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2S1010,96.8,1010,99.7
						C1010,99.7,1010,99.7,1010,99.7z" />
              <path className="st3" d="M1010,131.4c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2S1010,128.5,1010,131.4L1010,131.4z" />
              <path className="st3" d="M1010,163.2c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2S1010,160.3,1010,163.2L1010,163.2z" />
              <path className="st3" d="M1010,194.9c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2S1010,192,1010,194.9L1010,194.9z" />
              <path className="st3" d="M1010,226.6c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2S1010,223.7,1010,226.6L1010,226.6z" />
              <path className="st3" d="M1010,258.3c0,2.9,2.3,5.3,5.2,5.3c2.9,0,5.3-2.3,5.3-5.2c0-2.9-2.3-5.3-5.2-5.3c0,0,0,0,0,0
						C1012.3,253.1,1010,255.4,1010,258.3z" />
              <circle className="st3" cx="983.4" cy="4.5" r="5.2" />
              <circle className="st3" cx="983.4" cy="36.3" r="5.2" />
              <circle className="st3" cx="983.4" cy={68} r="5.2" />
              <path className="st3" d="M978.1,99.7c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2
						C980.5,94.5,978.1,96.8,978.1,99.7C978.1,99.7,978.1,99.7,978.1,99.7z" />
              <circle className="st3" cx="983.4" cy="131.4" r="5.2" />
              <path className="st3" d="M978.1,163.2c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2
						C980.5,157.9,978.1,160.3,978.1,163.2L978.1,163.2z" />
              <circle className="st3" cx="983.4" cy="194.9" r="5.2" />
              <path className="st3" d="M978.1,226.6c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2
						C980.5,221.4,978.1,223.7,978.1,226.6L978.1,226.6z" />
              <circle className="st3" cx="983.4" cy="258.4" r="5.2" />
              <path className="st3" d="M946.4,4.5c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2c0,0,0,0,0,0
						C948.8-0.7,946.4,1.6,946.4,4.5C946.4,4.5,946.4,4.5,946.4,4.5z" />
              <path className="st3" d="M946.4,36.3c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2S946.4,33.4,946.4,36.3
						C946.4,36.3,946.4,36.3,946.4,36.3z" />
              <path className="st3" d="M946.4,68c0.1,2.9,2.5,5.2,5.4,5.1c2.9-0.1,5.2-2.5,5.1-5.4c-0.1-2.8-2.4-5.1-5.2-5.1
						c-2.9,0-5.2,2.3-5.2,5.2C946.4,67.9,946.4,67.9,946.4,68z" />
              <path className="st3" d="M946.4,99.7c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2S946.4,96.8,946.4,99.7
						C946.4,99.7,946.4,99.7,946.4,99.7z" />
              <path className="st3" d="M946.4,131.4c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2S946.4,128.5,946.4,131.4
						L946.4,131.4z" />
              <path className="st3" d="M946.4,163.2c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2S946.4,160.3,946.4,163.2
						L946.4,163.2z" />
              <path className="st3" d="M946.4,194.9c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2S946.4,192,946.4,194.9L946.4,194.9z
						" />
              <circle className="st3" cx="919.9" cy={68} r="5.2" />
              <path className="st3" d="M914.7,99.7c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2c0-2.9-2.3-5.2-5.2-5.2
						C917,94.5,914.7,96.8,914.7,99.7C914.7,99.7,914.7,99.7,914.7,99.7z" />
              <circle className="st3" cx="919.9" cy="131.4" r="5.2" />
              <path className="st3" d="M914.7,163.2c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2
						C917,157.9,914.7,160.3,914.7,163.2L914.7,163.2z" />
              <g>
                <defs>
                  <path id="SVGID_1_" d="M934.6-3.3c-59.3,94.2-30.9,218.6,63.3,277.9s218.6,30.9,277.9-63.3c41.3-65.6,41.3-149,0-214.6H934.6
								z" />
                </defs>
                <clipPath id="SVGID_2_">
                  <use xlinkHref="#SVGID_1_" style={{overflow: 'visible'}} />
                </clipPath>
                <circle className="st4" cx="1300.6" cy={68} r="5.2" />
                <path className="st4" d="M1295.4,163.2c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
							C1297.7,157.9,1295.4,160.3,1295.4,163.2z" />
                <path className="st4" d="M1263.6,226.6c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2l0,0
							C1266,221.4,1263.6,223.7,1263.6,226.6z" />
                <circle className="st4" cx="1237.2" cy="258.4" r="5.2" />
                <path className="st4" d="M1168.5,290c0,2.9,2.3,5.3,5.2,5.3c2.9,0,5.3-2.3,5.3-5.2c0-2.9-2.3-5.3-5.2-5.3c0,0,0,0,0,0
							C1170.8,284.9,1168.5,287.2,1168.5,290z" />
                <path className="st4" d="M946.4,226.6c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2S946.4,223.7,946.4,226.6
							L946.4,226.6z" />
                <circle className="st4" cx="919.9" cy="36.3" r="5.2" />
                <circle className="st4" cx="919.9" cy="194.9" r="5.2" />
              </g>
            </g>
          </g>
          <g>
            <path className="st3" d="M318.3,1915.1c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.2-3.4-7.7-7.6-7.7
					c-4.2-0.1-7.7,3.3-7.8,7.5C318.3,1915,318.3,1915,318.3,1915.1z" />
            <path className="st3" d="M271.6,1774.9c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C275,1767.3,271.6,1770.7,271.6,1774.9z" />
            <path className="st3" d="M271.6,1821.8c0,4.3,3.4,7.7,7.7,7.7s7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7l0,0
					C275,1814.1,271.6,1817.6,271.6,1821.8z" />
            <path className="st3" d="M271.6,1868.4c0,4.3,3.4,7.7,7.7,7.7s7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7l0,0
					C275,1860.7,271.6,1864.1,271.6,1868.4z" />
            <path className="st3" d="M271.6,1915.1c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7s-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C275,1907.4,271.6,1910.8,271.6,1915.1z" />
            <path className="st3" d="M271.6,1961.9c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C275,1954.2,271.6,1957.7,271.6,1961.9z" />
            <path className="st3" d="M271.6,2008.5c0,4.3,3.4,7.7,7.7,7.7s7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7l0,0
					C275,2000.8,271.6,2004.2,271.6,2008.5z" />
            <path className="st3" d="M224.9,1728.3c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C228.3,1720.6,224.9,1724,224.9,1728.3C224.9,1728.2,224.9,1728.3,224.9,1728.3z" />
            <path className="st3" d="M224.9,1774.9c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0-0.1,0-0.1,0
					C228.3,1767.3,224.9,1770.7,224.9,1774.9z" />
            <path className="st3" d="M224.9,1821.8c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C228.3,1814.1,224.9,1817.6,224.9,1821.8z" />
            <path className="st3" d="M224.9,1868.4c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C228.3,1860.7,224.9,1864.1,224.9,1868.4z" />
            <path className="st3" d="M224.9,1915.1c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0-0.1,0-0.1,0
					C228.3,1907.4,224.9,1910.8,224.9,1915.1z" />
            <path className="st3" d="M224.9,1961.9c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0-0.1,0-0.1,0
					C228.3,1954.3,224.9,1957.7,224.9,1961.9z" />
            <path className="st3" d="M224.9,2008.5c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C228.3,2000.8,224.9,2004.3,224.9,2008.5z" />
            <path className="st3" d="M178.2,1681.8c0.1,4.3,3.7,7.6,7.9,7.5c4.3-0.1,7.6-3.7,7.5-7.9c-0.1-4.1-3.5-7.4-7.6-7.5
					c-4.3-0.1-7.8,3.3-7.8,7.6C178.2,1681.5,178.2,1681.6,178.2,1681.8z" />
            <path className="st3" d="M178.2,1728.3c0,4.3,3.5,7.7,7.7,7.7c4.3,0,7.7-3.5,7.7-7.7c0-4.2-3.4-7.6-7.6-7.7
					c-4.3-0.1-7.8,3.3-7.8,7.6C178.2,1728.2,178.2,1728.2,178.2,1728.3z" />
            <path className="st3" d="M178.2,1774.9c0,4.3,3.4,7.7,7.7,7.7s7.7-3.4,7.7-7.7c0-4.2-3.4-7.7-7.6-7.7
					C181.7,1767.2,178.2,1770.6,178.2,1774.9C178.2,1774.9,178.2,1774.9,178.2,1774.9z" />
            <path className="st3" d="M178.2,1821.8c0.1,4.3,3.6,7.6,7.9,7.5c4.3-0.1,7.6-3.6,7.5-7.9c-0.1-4.1-3.4-7.5-7.6-7.5
					c-4.3-0.1-7.8,3.3-7.8,7.6C178.2,1821.6,178.2,1821.7,178.2,1821.8z" />
            <path className="st3" d="M178.2,1868.4c0,4.3,3.5,7.7,7.7,7.7c4.3,0,7.7-3.5,7.7-7.7c0-4.2-3.4-7.6-7.6-7.7
					c-4.3-0.1-7.8,3.3-7.8,7.6C178.2,1868.3,178.2,1868.3,178.2,1868.4z" />
            <path className="st3" d="M178.2,1915.1c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.2-3.4-7.7-7.6-7.7
					C181.7,1907.3,178.2,1910.7,178.2,1915.1C178.2,1915,178.2,1915,178.2,1915.1z" />
            <path className="st3" d="M178.2,1961.9c0,4.3,3.5,7.7,7.8,7.6c4.3,0,7.7-3.5,7.6-7.8c0-4.2-3.4-7.6-7.6-7.6
					c-4.3-0.1-7.8,3.3-7.8,7.6C178.2,1961.8,178.2,1961.8,178.2,1961.9z" />
            <path className="st3" d="M178.2,2008.5c0,4.3,3.5,7.7,7.7,7.7c4.3,0,7.7-3.5,7.7-7.7c0-4.2-3.4-7.6-7.6-7.7
					c-4.3-0.1-7.8,3.3-7.8,7.6C178.2,2008.4,178.2,2008.5,178.2,2008.5z" />
            <path className="st3" d="M131.5,1681.8c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C134.9,1674,131.5,1677.5,131.5,1681.8z" />
            <path className="st3" d="M131.5,1728.3c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C134.9,1720.6,131.5,1724,131.5,1728.3z" />
            <path className="st3" d="M131.5,1774.9c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C134.9,1767.3,131.5,1770.7,131.5,1774.9z" />
            <path className="st3" d="M131.5,1821.8c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C134.9,1814.1,131.5,1817.6,131.5,1821.8z" />
            <path className="st3" d="M131.5,1868.4c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C134.9,1860.7,131.5,1864.1,131.5,1868.4z" />
            <path className="st3" d="M131.5,1915.1c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7s-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C134.9,1907.4,131.5,1910.8,131.5,1915.1z" />
            <path className="st3" d="M131.5,1961.9c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C134.9,1954.2,131.5,1957.7,131.5,1961.9z" />
            <path className="st3" d="M131.5,2008.5c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C134.9,2000.8,131.5,2004.2,131.5,2008.5z" />
            <path className="st3" d="M84.8,1681.8c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C88.2,1674.1,84.8,1677.5,84.8,1681.8z" />
            <path className="st3" d="M84.8,1728.3c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C88.2,1720.6,84.8,1724,84.8,1728.3C84.8,1728.2,84.8,1728.3,84.8,1728.3z" />
            <path className="st3" d="M84.8,1774.9c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0-0.1,0-0.1,0
					C88.2,1767.3,84.8,1770.7,84.8,1774.9z" />
            <path className="st3" d="M84.8,1821.8c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C88.2,1814.1,84.8,1817.6,84.8,1821.8z" />
            <path className="st3" d="M84.8,1868.4c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C88.2,1860.7,84.8,1864.1,84.8,1868.4z" />
            <path className="st3" d="M84.8,1915.1c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0-0.1,0-0.1,0
					C88.2,1907.4,84.8,1910.8,84.8,1915.1z" />
            <path className="st3" d="M84.8,1961.9c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0-0.1,0-0.1,0
					C88.2,1954.3,84.8,1957.7,84.8,1961.9z" />
            <path className="st3" d="M84.8,2008.5c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C88.2,2000.8,84.8,2004.3,84.8,2008.5z" />
            <path className="st3" d="M38.1,1681.8c0.1,4.3,3.7,7.6,7.9,7.5s7.6-3.7,7.5-7.9c-0.1-4.1-3.4-7.4-7.5-7.5c-4.3-0.1-7.8,3.3-7.9,7.5
					C38.1,1681.5,38.1,1681.6,38.1,1681.8z" />
            <path className="st3" d="M38.1,1728.3c0,4.3,3.5,7.7,7.7,7.7c4.3,0,7.7-3.5,7.7-7.7c0-4.2-3.4-7.6-7.5-7.7c-4.3-0.1-7.8,3.3-7.9,7.5
					C38.1,1728.2,38.1,1728.2,38.1,1728.3z" />
            <path className="st3" d="M38.1,1774.9c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.2-3.3-7.7-7.5-7.7c-4.3-0.1-7.8,3.3-7.9,7.5
					C38.1,1774.9,38.1,1774.9,38.1,1774.9z" />
            <path className="st3" d="M38.1,1821.8c0.1,4.3,3.6,7.6,7.9,7.5c4.3-0.1,7.6-3.6,7.5-7.9c-0.1-4.1-3.4-7.4-7.5-7.5
					c-4.3-0.1-7.8,3.3-7.9,7.5C38.1,1821.6,38.1,1821.7,38.1,1821.8z" />
            <path className="st3" d="M38.1,1868.4c0,4.3,3.5,7.7,7.7,7.7c4.3,0,7.7-3.5,7.7-7.7c0-4.2-3.4-7.6-7.5-7.7c-4.3-0.1-7.8,3.3-7.9,7.5
					C38.1,1868.3,38.1,1868.3,38.1,1868.4z" />
            <path className="st3" d="M38.1,1915.1c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.2-3.3-7.7-7.5-7.7c-4.3-0.1-7.8,3.3-7.9,7.5
					C38.1,1915,38.1,1915,38.1,1915.1z" />
            <path className="st3" d="M38.1,1961.9c0,4.3,3.5,7.7,7.8,7.6c4.3,0,7.7-3.5,7.6-7.8c0-4.2-3.4-7.5-7.5-7.6c-4.3-0.1-7.8,3.3-7.9,7.5
					C38.1,1961.7,38.1,1961.8,38.1,1961.9z" />
            <path className="st3" d="M38.1,2008.5c0,4.3,3.5,7.7,7.7,7.7c4.3,0,7.7-3.5,7.7-7.7c0-4.2-3.4-7.6-7.5-7.7c-4.3-0.1-7.8,3.3-7.9,7.5
					C38.1,2008.4,38.1,2008.4,38.1,2008.5z" />
            <path className="st3" d="M-8.6,1681.8c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7s-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C-5.2,1674.1-8.6,1677.5-8.6,1681.8z" />
            <path className="st3" d="M-8.6,1728.3c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7s-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C-5.2,1720.6-8.6,1724-8.6,1728.3C-8.6,1728.2-8.6,1728.3-8.6,1728.3z" />
            <path className="st3" d="M-8.6,1774.9c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0-0.1,0-0.1,0
					C-5.2,1767.3-8.6,1770.7-8.6,1774.9z" />
            <path className="st3" d="M-8.6,1821.8c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7s-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C-5.2,1814.1-8.6,1817.6-8.6,1821.8z" />
            <path className="st3" d="M-8.6,1868.4c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C-5.2,1860.7-8.6,1864.1-8.6,1868.4z" />
            <path className="st3" d="M-8.6,1915.1c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0-0.1,0-0.1,0
					C-5.2,1907.4-8.6,1910.8-8.6,1915.1z" />
            <path className="st3" d="M-8.6,1961.9c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0-0.1,0-0.1,0
					C-5.2,1954.3-8.6,1957.7-8.6,1961.9z" />
            <path className="st3" d="M-8.6,2008.5c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7s-3.4-7.7-7.7-7.7c0,0,0,0,0,0
					C-5.2,2000.8-8.6,2004.3-8.6,2008.5z" />
            <g>
              <defs>
                <path id="SVGID_3_" d="M302.1,2056.7c21.5-41.9,32.7-88.2,32.7-135.3c0.2-163.7-132.4-296.5-296-296.7
							c-18.3,0-36.6,1.7-54.7,5v426.9H302.1z" />
              </defs>
              <clipPath id="SVGID_4_">
                <use xlinkHref="#SVGID_3_" style={{overflow: 'visible'}} />
              </clipPath>
              <g className="st5">
                <g>
                  <path className="st3" d="M318.3,1868.4c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.2-3.4-7.7-7.6-7.7
								C321.8,1860.6,318.3,1864,318.3,1868.4C318.3,1868.3,318.3,1868.4,318.3,1868.4z" />
                </g>
                <g>
                  <path className="st3" d="M318.3,1961.9c0,4.3,3.5,7.7,7.8,7.6s7.7-3.5,7.6-7.8c0-4.2-3.4-7.6-7.6-7.6c-4.3,0-7.7,3.4-7.8,7.6
								C318.3,1961.8,318.3,1961.9,318.3,1961.9z" />
                </g>
                <g>
                  <path className="st3" d="M318.3,2008.5c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.2-3.4-7.7-7.6-7.7
								C321.8,2000.8,318.3,2004.2,318.3,2008.5C318.3,2008.4,318.3,2008.5,318.3,2008.5z" />
                </g>
                <g>
                  <path className="st3" d="M271.6,2055.2c0,4.3,3.4,7.7,7.7,7.7s7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7l0,0
								C275,2047.5,271.6,2051,271.6,2055.2z" />
                </g>
                <g>
                  <path className="st3" d="M224.9,2055.2c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
								C228.3,2047.5,224.9,2051,224.9,2055.2C224.9,2055.2,224.9,2055.2,224.9,2055.2z" />
                </g>
                <g>
                  <path className="st3" d="M178.2,2055.2c0,4.3,3.5,7.7,7.7,7.7s7.7-3.5,7.7-7.7c0-4.2-3.4-7.6-7.6-7.7c-4.3-0.1-7.8,3.3-7.8,7.6
								C178.2,2055.1,178.2,2055.2,178.2,2055.2z" />
                </g>
                <g>
                  <path className="st3" d="M131.5,1634.8c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
								C134.9,1627.2,131.5,1630.6,131.5,1634.8z" />
                </g>
                <g>
                  <path className="st3" d="M84.8,1634.8c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0-0.1,0-0.1,0
								C88.2,1627.2,84.8,1630.6,84.8,1634.8z" />
                </g>
                <g>
                  <path className="st3" d="M131.5,2055.2c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
								C134.9,2047.5,131.5,2051,131.5,2055.2z" />
                </g>
                <g>
                  <path className="st3" d="M84.8,2055.2c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
								C88.2,2047.5,84.8,2051,84.8,2055.2C84.8,2055.2,84.8,2055.2,84.8,2055.2z" />
                </g>
                <g>
                  <path className="st3" d="M38.1,1634.8c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.2-3.3-7.7-7.5-7.7
								c-4.3-0.1-7.8,3.3-7.9,7.5C38.1,1634.7,38.1,1634.8,38.1,1634.8z" />
                </g>
                <g>
                  <path className="st3" d="M38.1,2055.2c0,4.3,3.5,7.7,7.7,7.7c4.3,0,7.7-3.5,7.7-7.7c0-4.2-3.4-7.6-7.5-7.7
								c-4.3-0.1-7.8,3.3-7.9,7.5C38.1,2055.1,38.1,2055.2,38.1,2055.2z" />
                </g>
                <g>
                  <path className="st3" d="M-8.6,1634.8c0,4.3,3.4,7.7,7.7,7.7s7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0-0.1,0-0.1,0
								C-5.2,1627.2-8.6,1630.6-8.6,1634.8z" />
                </g>
                <g>
                  <path className="st3" d="M-8.6,2055.2c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c0,0,0,0,0,0
								C-5.2,2047.5-8.6,2051-8.6,2055.2C-8.6,2055.2-8.6,2055.2-8.6,2055.2z" />
                </g>
              </g>
            </g>
          </g>
          <g>
            <defs>
              <path id="SVGID_5_" d="M967.7,1285.3c0,109.4-88.7,198-198,198c-109.4,0-198-88.7-198-198c0-109.4,88.7-198,198-198
						S967.7,1175.9,967.7,1285.3L967.7,1285.3z" />
            </defs>
            <clipPath id="SVGID_6_">
              <use xlinkHref="#SVGID_5_" style={{overflow: 'visible'}} />
            </clipPath>
            <g className="st6">
              <path className="st3" d="M956.7,1249.9c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C959,1244.8,956.7,1247.1,956.7,1249.9z" />
              <path className="st3" d="M956.7,1281.1c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C959,1276,956.7,1278.3,956.7,1281.1z" />
              <path className="st3" d="M956.7,1312.3c-0.3,2.8,1.8,5.3,4.7,5.6c2.8,0.3,5.3-1.8,5.6-4.7c0-0.3,0-0.6,0-0.9
						c-0.3-2.8-2.8-4.9-5.6-4.7C958.9,1307.8,956.9,1309.8,956.7,1312.3z" />
              <path className="st3" d="M956.7,1343.5c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C959,1338.3,956.7,1340.6,956.7,1343.5z" />
              <path className="st3" d="M925.5,1187.6c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C927.8,1182.4,925.5,1184.7,925.5,1187.6z" />
              <path className="st3" d="M925.5,1218.8c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C927.8,1213.6,925.5,1215.9,925.5,1218.8z" />
              <path className="st3" d="M925.5,1249.9c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C927.8,1244.8,925.5,1247.1,925.5,1249.9z" />
              <path className="st3" d="M925.5,1281.1c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C927.8,1276,925.5,1278.3,925.5,1281.1z" />
              <path className="st3" d="M925.5,1312.3c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C927.8,1307.2,925.5,1309.5,925.5,1312.3z" />
              <path className="st3" d="M925.5,1343.5c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C927.8,1338.3,925.5,1340.6,925.5,1343.5z" />
              <path className="st3" d="M925.5,1374.7c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1
						C927.8,1369.5,925.5,1371.8,925.5,1374.7z" />
              <path className="st3" d="M925.5,1405.7c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C927.8,1400.6,925.5,1402.9,925.5,1405.7z" />
              <path className="st3" d="M894.3,1156.4c0.3,2.8,2.8,4.9,5.6,4.7c2.5-0.2,4.4-2.2,4.7-4.7c0.3-2.8-1.8-5.3-4.7-5.6
						c-2.8-0.3-5.3,1.8-5.6,4.7C894.3,1155.8,894.3,1156.1,894.3,1156.4z" />
              <path className="st3" d="M894.3,1187.6c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C896.6,1182.4,894.3,1184.7,894.3,1187.6C894.3,1187.5,894.3,1187.6,894.3,1187.6z" />
              <path className="st3" d="M894.3,1218.8c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C896.6,1213.6,894.3,1215.9,894.3,1218.8C894.3,1218.7,894.3,1218.7,894.3,1218.8z" />
              <path className="st3" d="M894.3,1249.9c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0-0.1,0-0.1,0
						C896.6,1244.8,894.3,1247.1,894.3,1249.9z" />
              <path className="st3" d="M894.3,1281.1c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1s-2.3-5.2-5.1-5.2c0,0-0.1,0-0.1,0
						C896.6,1276,894.3,1278.3,894.3,1281.1z" />
              <path className="st3" d="M894.3,1312.3c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0-0.1,0-0.1,0
						C896.6,1307.2,894.3,1309.5,894.3,1312.3z" />
              <path className="st3" d="M894.3,1343.5c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C896.6,1338.3,894.3,1340.6,894.3,1343.5C894.3,1343.4,894.3,1343.5,894.3,1343.5z" />
              <path className="st3" d="M894.3,1374.7c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2s-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C896.6,1369.5,894.3,1371.8,894.3,1374.7C894.3,1374.6,894.3,1374.6,894.3,1374.7z" />
              <path className="st3" d="M894.3,1405.7c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2s-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C896.6,1400.6,894.3,1402.9,894.3,1405.7C894.3,1405.7,894.3,1405.7,894.3,1405.7z" />
              <path className="st3" d="M894.3,1437c0.3,2.8,2.8,4.9,5.6,4.7c2.5-0.2,4.4-2.2,4.7-4.7c0.3-2.8-1.8-5.3-4.7-5.6
						c-2.8-0.3-5.3,1.8-5.6,4.7C894.3,1436.4,894.3,1436.7,894.3,1437z" />
              <path className="st3" d="M863.2,1125.2c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C865.4,1120.1,863.2,1122.4,863.2,1125.2z" />
              <path className="st3" d="M863.2,1156.4c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2s-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C865.4,1151.2,863.2,1153.5,863.2,1156.4C863.2,1156.4,863.2,1156.4,863.2,1156.4z" />
              <path className="st3" d="M863.2,1187.6c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C865.4,1182.4,863.2,1184.7,863.2,1187.6z" />
              <path className="st3" d="M863.2,1218.8c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C865.4,1213.6,863.2,1215.9,863.2,1218.8z" />
              <path className="st3" d="M863.2,1249.9c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0-0.1,0-0.1,0
						C865.4,1244.8,863.2,1247.1,863.2,1249.9z" />
              <path className="st3" d="M863.2,1281.1c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1s-2.3-5.2-5.1-5.2c0,0-0.1,0-0.1,0
						C865.4,1276,863.2,1278.3,863.2,1281.1z" />
              <path className="st3" d="M863.2,1312.3c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0-0.1,0-0.1,0
						C865.4,1307.2,863.2,1309.5,863.2,1312.3z" />
              <path className="st3" d="M863.2,1343.5c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C865.4,1338.3,863.2,1340.6,863.2,1343.5z" />
              <path className="st3" d="M863.2,1374.7c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2s-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C865.4,1369.5,863.2,1371.8,863.2,1374.7z" />
              <path className="st3" d="M863.2,1405.7c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2s-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C865.4,1400.6,863.2,1402.9,863.2,1405.7z" />
              <path className="st3" d="M863.2,1437c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C865.4,1431.9,863.2,1434.2,863.2,1437C863.2,1437,863.2,1437,863.2,1437z" />
              <path className="st3" d="M832,1094c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1
						C834.3,1088.9,832,1091.2,832,1094z" />
              <path className="st3" d="M832,1125.2c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C834.3,1120,832,1122.4,832,1125.2z" />
              <path className="st3" d="M832,1156.4c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C834.3,1151.2,832,1153.5,832,1156.4C832,1156.3,832,1156.4,832,1156.4z" />
              <path className="st3" d="M832,1187.6c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2s-2.3-5.1-5.2-5.1c-0.1,0-0.1,0-0.2,0
						C834.2,1182.5,832,1184.8,832,1187.6z" />
              <path className="st3" d="M832,1218.8c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C834.3,1213.6,832,1215.9,832,1218.8z" />
              <path className="st3" d="M832,1249.9c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C834.3,1244.8,832,1247.1,832,1249.9z" />
              <path className="st3" d="M832,1281.1c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C834.3,1276,832,1278.3,832,1281.1z" />
              <path className="st3" d="M832,1312.3c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C834.3,1307.2,832,1309.5,832,1312.3z" />
              <path className="st3" d="M832,1343.5c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C834.3,1338.3,832,1340.6,832,1343.5z" />
              <path className="st3" d="M832,1374.7c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1
						C834.3,1369.5,832,1371.8,832,1374.7z" />
              <path className="st3" d="M832,1405.7c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C834.3,1400.6,832,1402.9,832,1405.7z" />
              <path className="st3" d="M832,1437c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C834.3,1431.9,832,1434.1,832,1437C832,1437,832,1437,832,1437z" />
              <path className="st3" d="M832,1468.2c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1
						C834.3,1463,832,1465.3,832,1468.2C832,1468.2,832,1468.2,832,1468.2z" />
              <path className="st3" d="M800.8,1094c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1S800.8,1091.2,800.8,1094z" />
              <path className="st3" d="M800.8,1125.2c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S800.8,1122.4,800.8,1125.2z" />
              <path className="st3" d="M800.8,1156.4c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C803.1,1151.2,800.8,1153.5,800.8,1156.4C800.8,1156.3,800.8,1156.4,800.8,1156.4z" />
              <path className="st3" d="M800.8,1187.6c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S800.8,1184.7,800.8,1187.6z" />
              <path className="st3" d="M800.8,1218.8c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S800.8,1215.9,800.8,1218.8z" />
              <path className="st3" d="M800.8,1249.9c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C803.1,1244.8,800.8,1247.1,800.8,1249.9z" />
              <path className="st3" d="M800.8,1281.1c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C803.1,1276,800.8,1278.3,800.8,1281.1z" />
              <path className="st3" d="M800.8,1312.3c-0.3,2.8,1.8,5.3,4.7,5.6c2.8,0.3,5.3-1.8,5.6-4.7c0-0.3,0-0.6,0-0.9
						c-0.3-2.8-2.8-4.9-5.6-4.7C803,1307.8,801,1309.8,800.8,1312.3z" />
              <path className="st3" d="M800.8,1343.5c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S800.8,1340.6,800.8,1343.5z" />
              <path className="st3" d="M800.8,1374.7c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1S800.8,1371.8,800.8,1374.7z" />
              <path className="st3" d="M800.8,1405.7c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S800.8,1402.9,800.8,1405.7z" />
              <path className="st3" d="M800.8,1437c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C803.1,1431.9,800.8,1434.1,800.8,1437C800.8,1437,800.8,1437,800.8,1437z" />
              <path className="st3" d="M800.8,1468.2c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1
						C803.1,1463,800.8,1465.3,800.8,1468.2C800.8,1468.2,800.8,1468.2,800.8,1468.2z" />
              <path className="st3" d="M769.6,1094c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1S769.6,1091.2,769.6,1094z" />
              <path className="st3" d="M769.6,1125.2c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S769.6,1122.4,769.6,1125.2z" />
              <path className="st3" d="M769.6,1156.4c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C771.9,1151.2,769.6,1153.5,769.6,1156.4C769.6,1156.3,769.6,1156.4,769.6,1156.4z" />
              <path className="st3" d="M769.6,1187.6c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S769.6,1184.7,769.6,1187.6z" />
              <path className="st3" d="M769.6,1218.8c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S769.6,1215.9,769.6,1218.8z" />
              <path className="st3" d="M769.6,1249.9c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C771.9,1244.8,769.6,1247.1,769.6,1249.9z" />
              <path className="st3" d="M769.6,1281.1c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C771.9,1276,769.6,1278.3,769.6,1281.1z" />
              <path className="st3" d="M769.6,1312.3c-0.3,2.8,1.8,5.3,4.7,5.6c2.8,0.3,5.3-1.8,5.6-4.7c0-0.3,0-0.6,0-0.9
						c-0.3-2.8-2.8-4.9-5.6-4.7C771.8,1307.8,769.8,1309.8,769.6,1312.3z" />
              <path className="st3" d="M769.6,1343.5c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S769.6,1340.6,769.6,1343.5z" />
              <path className="st3" d="M769.6,1374.7c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1S769.6,1371.8,769.6,1374.7z" />
              <path className="st3" d="M769.6,1405.7c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S769.6,1402.9,769.6,1405.7z" />
              <path className="st3" d="M769.6,1437c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C771.9,1431.9,769.6,1434.1,769.6,1437C769.6,1437,769.6,1437,769.6,1437z" />
              <path className="st3" d="M769.6,1468.2c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1
						C771.9,1463,769.6,1465.3,769.6,1468.2C769.6,1468.2,769.6,1468.2,769.6,1468.2z" />
              <path className="st3" d="M738.4,1094c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1S738.4,1091.2,738.4,1094z" />
              <path className="st3" d="M738.4,1125.2c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S738.4,1122.4,738.4,1125.2z" />
              <path className="st3" d="M738.4,1156.4c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C740.7,1151.2,738.4,1153.5,738.4,1156.4C738.4,1156.3,738.4,1156.4,738.4,1156.4z" />
              <path className="st3" d="M738.4,1187.6c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S738.4,1184.7,738.4,1187.6z" />
              <path className="st3" d="M738.4,1218.8c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S738.4,1215.9,738.4,1218.8z" />
              <path className="st3" d="M738.4,1249.9c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C740.7,1244.8,738.4,1247.1,738.4,1249.9z" />
              <path className="st3" d="M738.4,1281.1c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C740.7,1276,738.4,1278.3,738.4,1281.1z" />
              <path className="st3" d="M738.4,1312.3c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C740.7,1307.2,738.4,1309.5,738.4,1312.3z" />
              <path className="st3" d="M738.4,1343.5c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S738.4,1340.6,738.4,1343.5z" />
              <path className="st3" d="M738.4,1374.7c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1S738.4,1371.8,738.4,1374.7z" />
              <path className="st3" d="M738.4,1405.7c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S738.4,1402.9,738.4,1405.7z" />
              <path className="st3" d="M738.4,1437c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C740.7,1431.9,738.4,1434.1,738.4,1437C738.4,1437,738.4,1437,738.4,1437z" />
              <path className="st3" d="M738.4,1468.2c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1
						C740.7,1463,738.4,1465.3,738.4,1468.2C738.4,1468.2,738.4,1468.2,738.4,1468.2z" />
              <path className="st3" d="M707.3,1094c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2s-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C709.5,1088.9,707.3,1091.1,707.3,1094C707.3,1094,707.3,1094,707.3,1094z" />
              <path className="st3" d="M707.3,1125.2c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C709.5,1120,707.3,1122.3,707.3,1125.2C707.3,1125.2,707.3,1125.2,707.3,1125.2z" />
              <path className="st3" d="M707.3,1156.4c0.3,2.8,2.8,4.9,5.6,4.7c2.5-0.2,4.4-2.2,4.7-4.7c0.3-2.8-1.8-5.3-4.7-5.6
						c-2.8-0.3-5.3,1.8-5.6,4.7C707.2,1155.8,707.2,1156.1,707.3,1156.4z" />
              <path className="st3" d="M707.3,1187.6c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C709.5,1182.4,707.3,1184.7,707.3,1187.6C707.3,1187.5,707.3,1187.6,707.3,1187.6z" />
              <path className="st3" d="M707.3,1218.8c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C709.5,1213.6,707.3,1215.9,707.3,1218.8C707.3,1218.7,707.3,1218.7,707.3,1218.8z" />
              <path className="st3" d="M707.3,1249.9c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0-0.1,0-0.1,0
						C709.5,1244.8,707.3,1247.1,707.3,1249.9z" />
              <path className="st3" d="M707.3,1281.1c0,2.8,2.3,5.2,5.1,5.2s5.2-2.3,5.2-5.1s-2.3-5.2-5.1-5.2c0,0-0.1,0-0.1,0
						C709.5,1276,707.3,1278.3,707.3,1281.1z" />
              <path className="st3" d="M707.3,1312.3c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0-0.1,0-0.1,0
						C709.5,1307.2,707.3,1309.5,707.3,1312.3z" />
              <path className="st3" d="M707.3,1343.5c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C709.5,1338.3,707.3,1340.6,707.3,1343.5C707.3,1343.4,707.3,1343.5,707.3,1343.5z" />
              <path className="st3" d="M707.3,1374.7c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2s-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C709.5,1369.5,707.3,1371.8,707.3,1374.7C707.3,1374.6,707.3,1374.6,707.3,1374.7z" />
              <path className="st3" d="M707.3,1405.7c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2s-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C709.5,1400.6,707.3,1402.9,707.3,1405.7C707.3,1405.7,707.3,1405.7,707.3,1405.7z" />
              <path className="st3" d="M707.3,1437c0.3,2.8,2.8,4.9,5.6,4.7c2.5-0.2,4.4-2.2,4.7-4.7c0.3-2.8-1.8-5.3-4.7-5.6
						c-2.8-0.3-5.3,1.8-5.6,4.7C707.2,1436.4,707.2,1436.7,707.3,1437z" />
              <path className="st3" d="M707.3,1468.2c0.3,2.8,2.8,4.9,5.6,4.7c2.5-0.2,4.4-2.2,4.7-4.7c0.3-2.8-1.8-5.3-4.7-5.6
						c-2.8-0.3-5.3,1.8-5.6,4.7C707.2,1467.6,707.2,1467.9,707.3,1468.2z" />
              <path className="st3" d="M676.3,1125.2c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C678.6,1120.1,676.3,1122.4,676.3,1125.2z" />
              <path className="st3" d="M676.3,1156.4c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2s-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C678.6,1151.2,676.3,1153.5,676.3,1156.4C676.3,1156.4,676.3,1156.4,676.3,1156.4z" />
              <path className="st3" d="M676.3,1187.6c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C678.6,1182.4,676.3,1184.7,676.3,1187.6z" />
              <path className="st3" d="M676.3,1218.8c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C678.6,1213.6,676.3,1215.9,676.3,1218.8z" />
              <path className="st3" d="M676.3,1249.9c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0-0.1,0-0.1,0
						C678.6,1244.8,676.3,1247.1,676.3,1249.9z" />
              <path className="st3" d="M676.3,1281.1c0,2.8,2.3,5.2,5.1,5.2s5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0-0.1,0-0.1,0
						C678.6,1276,676.3,1278.3,676.3,1281.1z" />
              <path className="st3" d="M676.3,1312.3c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0-0.1,0-0.1,0
						C678.6,1307.2,676.3,1309.5,676.3,1312.3z" />
              <path className="st3" d="M676.3,1343.5c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C678.6,1338.3,676.3,1340.6,676.3,1343.5z" />
              <path className="st3" d="M676.3,1374.7c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2s-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C678.6,1369.5,676.3,1371.8,676.3,1374.7z" />
              <path className="st3" d="M676.3,1405.7c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2s-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C678.6,1400.6,676.3,1402.9,676.3,1405.7z" />
              <path className="st3" d="M676.3,1437c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C678.6,1431.9,676.3,1434.2,676.3,1437C676.3,1437,676.3,1437,676.3,1437z" />
              <path className="st3" d="M676.3,1468.2c0,2.8,2.3,5.1,5.2,5.1c2.8,0,5.1-2.3,5.1-5.2c0-2.8-2.3-5.1-5.2-5.1c0,0,0,0,0,0
						C678.6,1463.1,676.3,1465.4,676.3,1468.2C676.3,1468.2,676.3,1468.2,676.3,1468.2z" />
              <path className="st3" d="M644.9,1125.2c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C647.2,1120,644.9,1122.4,644.9,1125.2z" />
              <path className="st3" d="M644.9,1156.4c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C647.2,1151.2,644.9,1153.5,644.9,1156.4C644.9,1156.3,644.9,1156.4,644.9,1156.4z" />
              <path className="st3" d="M644.9,1187.6c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C647.2,1182.4,644.9,1184.7,644.9,1187.6z" />
              <path className="st3" d="M644.9,1218.8c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C647.2,1213.6,644.9,1215.9,644.9,1218.8z" />
              <path className="st3" d="M644.9,1249.9c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C647.2,1244.8,644.9,1247.1,644.9,1249.9z" />
              <path className="st3" d="M644.9,1281.1c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C647.2,1276,644.9,1278.3,644.9,1281.1z" />
              <path className="st3" d="M644.9,1312.3c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C647.2,1307.2,644.9,1309.5,644.9,1312.3z" />
              <path className="st3" d="M644.9,1343.5c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C647.2,1338.3,644.9,1340.6,644.9,1343.5z" />
              <path className="st3" d="M644.9,1374.7c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1
						C647.2,1369.5,644.9,1371.8,644.9,1374.7z" />
              <path className="st3" d="M644.9,1405.7c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C647.2,1400.6,644.9,1402.9,644.9,1405.7z" />
              <path className="st3" d="M644.9,1437c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C647.2,1431.9,644.9,1434.1,644.9,1437C644.9,1437,644.9,1437,644.9,1437z" />
              <path className="st3" d="M613.7,1156.4c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C616,1151.2,613.7,1153.5,613.7,1156.4C613.7,1156.3,613.7,1156.4,613.7,1156.4z" />
              <path className="st3" d="M613.7,1187.6c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C616,1182.4,613.7,1184.7,613.7,1187.6z" />
              <path className="st3" d="M613.7,1218.8c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C616,1213.6,613.7,1215.9,613.7,1218.8z" />
              <path className="st3" d="M613.7,1249.9c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C616,1244.8,613.7,1247.1,613.7,1249.9z" />
              <path className="st3" d="M613.7,1281.1c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C616,1276,613.7,1278.3,613.7,1281.1z" />
              <path className="st3" d="M613.7,1312.3c-0.3,2.8,1.8,5.3,4.7,5.6c2.8,0.3,5.3-1.8,5.6-4.7c0-0.3,0-0.6,0-0.9
						c-0.3-2.8-2.8-4.9-5.6-4.7C615.9,1307.8,613.9,1309.8,613.7,1312.3z" />
              <path className="st3" d="M613.7,1343.5c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C616,1338.3,613.7,1340.6,613.7,1343.5z" />
              <path className="st3" d="M613.7,1374.7c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1
						C616,1369.5,613.7,1371.8,613.7,1374.7z" />
              <path className="st3" d="M613.7,1405.7c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C616,1400.6,613.7,1402.9,613.7,1405.7z" />
              <path className="st3" d="M582.5,1218.8c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C584.8,1213.6,582.5,1215.9,582.5,1218.8z" />
              <path className="st3" d="M582.5,1249.9c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C584.8,1244.8,582.5,1247.1,582.5,1249.9z" />
              <path className="st3" d="M582.5,1281.1c0,2.8,2.3,5.2,5.1,5.2c2.8,0,5.2-2.3,5.2-5.1c0-2.8-2.3-5.2-5.1-5.2c0,0,0,0,0,0
						C584.8,1276,582.5,1278.3,582.5,1281.1z" />
              <path className="st3" d="M582.5,1312.3c-0.3,2.8,1.8,5.3,4.7,5.6c2.8,0.3,5.3-1.8,5.6-4.7c0-0.3,0-0.6,0-0.9
						c-0.3-2.8-2.8-4.9-5.6-4.7C584.7,1307.8,582.7,1309.8,582.5,1312.3z" />
              <path className="st3" d="M582.5,1343.5c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1
						C584.8,1338.3,582.5,1340.6,582.5,1343.5z" />
              <path className="st3" d="M582.5,1374.7c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1
						C584.8,1369.5,582.5,1371.8,582.5,1374.7z" />
            </g>
          </g>
          <g>
            <defs>
              <path id="SVGID_7_" d="M2318.1,1274.9c0,81.7-66.2,147.9-147.9,147.9c-81.7,0-147.9-66.2-147.9-147.9
						c0-81.7,66.2-147.9,147.9-147.9c0,0,0,0,0,0C2251.9,1127,2318.1,1193.2,2318.1,1274.9
						C2318.1,1274.9,2318.1,1274.9,2318.1,1274.9z" />
            </defs>
            <clipPath id="SVGID_8_">
              <use xlinkHref="#SVGID_7_" style={{overflow: 'visible'}} />
            </clipPath>
            <g className="st7">
              <path className="st3" d="M2309.8,1225.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2311.5,1221.4,2309.8,1223.1,2309.8,1225.2z" />
              <path className="st3" d="M2309.8,1248.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2311.5,1244.7,2309.8,1246.4,2309.8,1248.5z" />
              <path className="st3" d="M2309.8,1271.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2311.6,1267.9,2309.8,1269.6,2309.8,1271.8C2309.8,1271.8,2309.8,1271.8,2309.8,1271.8z" />
              <path className="st3" d="M2309.8,1295.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2311.5,1291.2,2309.8,1292.9,2309.8,1295.1z" />
              <path className="st3" d="M2309.8,1318.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2311.5,1314.5,2309.8,1316.2,2309.8,1318.3z" />
              <path className="st3" d="M2286.5,1201.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2288.3,1198.1,2286.5,1199.8,2286.5,1201.9z" />
              <path className="st3" d="M2286.5,1225.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2288.3,1221.4,2286.6,1223.1,2286.5,1225.2z" />
              <path className="st3" d="M2286.5,1248.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2288.3,1244.7,2286.5,1246.4,2286.5,1248.5z" />
              <path className="st3" d="M2286.5,1271.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2288.3,1267.9,2286.5,1269.7,2286.5,1271.8z" />
              <path className="st3" d="M2286.5,1295.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2288.3,1291.2,2286.5,1292.9,2286.5,1295.1z" />
              <path className="st3" d="M2286.5,1318.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2288.3,1314.5,2286.5,1316.2,2286.5,1318.3z" />
              <path className="st3" d="M2286.5,1341.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2288.3,1337.8,2286.6,1339.5,2286.5,1341.6z" />
              <path className="st3" d="M2286.5,1364.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2288.3,1361.1,2286.5,1362.8,2286.5,1364.9z" />
              <path className="st3" d="M2263.3,1178.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2265,1174.8,2263.3,1176.5,2263.3,1178.6z" />
              <path className="st3" d="M2263.3,1201.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2265,1198.1,2263.3,1199.8,2263.3,1201.9z" />
              <path className="st3" d="M2263.3,1225.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2265,1221.4,2263.3,1223.1,2263.3,1225.2z" />
              <path className="st3" d="M2263.3,1248.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2265,1244.7,2263.3,1246.4,2263.3,1248.5z" />
              <path className="st3" d="M2263.3,1271.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2265,1267.9,2263.3,1269.7,2263.3,1271.8z" />
              <path className="st3" d="M2263.3,1295.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2265,1291.2,2263.3,1292.9,2263.3,1295.1z" />
              <path className="st3" d="M2263.3,1318.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2265,1314.5,2263.3,1316.2,2263.3,1318.3z" />
              <path className="st3" d="M2263.3,1341.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2265,1337.8,2263.3,1339.5,2263.3,1341.6z" />
              <path className="st3" d="M2263.3,1364.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2265,1361.1,2263.3,1362.8,2263.3,1364.9z" />
              <path className="st3" d="M2263.3,1388.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2265,1384.4,2263.3,1386.1,2263.3,1388.2z" />
              <path className="st3" d="M2240,1155.4c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2241.7,1151.5,2240,1153.2,2240,1155.4z" />
              <path className="st3" d="M2240,1178.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2241.7,1174.8,2240,1176.5,2240,1178.6z" />
              <path className="st3" d="M2240,1201.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8S2240,1199.8,2240,1201.9
						C2240,1201.9,2240,1201.9,2240,1201.9z" />
              <path className="st3" d="M2240,1225.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2241.7,1221.4,2240,1223.1,2240,1225.2z" />
              <path className="st3" d="M2240,1248.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2241.7,1244.7,2240,1246.4,2240,1248.5z" />
              <path className="st3" d="M2240,1271.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2241.7,1267.9,2240,1269.7,2240,1271.8z" />
              <path className="st3" d="M2240,1295.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2241.7,1291.2,2240,1292.9,2240,1295.1z" />
              <path className="st3" d="M2240,1318.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8
						C2241.7,1314.5,2240,1316.2,2240,1318.3z" />
              <path className="st3" d="M2240,1341.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2241.7,1337.8,2240,1339.5,2240,1341.6z" />
              <path className="st3" d="M2240,1364.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2241.7,1361.1,2240,1362.8,2240,1364.9z" />
              <path className="st3" d="M2240,1388.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2241.7,1384.4,2240,1386.1,2240,1388.2z" />
              <path className="st3" d="M2216.7,1132.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2218.4,1128.2,2216.7,1130,2216.7,1132.1z" />
              <path className="st3" d="M2216.7,1155.4c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2218.4,1151.5,2216.7,1153.2,2216.7,1155.4z" />
              <path className="st3" d="M2216.7,1178.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2218.4,1174.8,2216.7,1176.5,2216.7,1178.6z" />
              <path className="st3" d="M2216.7,1201.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8
						C2218.4,1198.1,2216.7,1199.8,2216.7,1201.9C2216.7,1201.9,2216.7,1201.9,2216.7,1201.9z" />
              <path className="st3" d="M2216.7,1225.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2218.4,1221.4,2216.7,1223.1,2216.7,1225.2z" />
              <path className="st3" d="M2216.7,1248.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2218.4,1244.7,2216.7,1246.4,2216.7,1248.5z" />
              <path className="st3" d="M2216.7,1271.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2218.4,1267.9,2216.7,1269.7,2216.7,1271.8z" />
              <path className="st3" d="M2216.7,1295.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2218.4,1291.2,2216.7,1292.9,2216.7,1295.1z" />
              <path className="st3" d="M2216.7,1318.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8
						C2218.4,1314.5,2216.7,1316.2,2216.7,1318.3z" />
              <path className="st3" d="M2216.7,1341.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2218.4,1337.8,2216.7,1339.5,2216.7,1341.6z" />
              <path className="st3" d="M2216.7,1364.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2218.4,1361.1,2216.7,1362.8,2216.7,1364.9z" />
              <path className="st3" d="M2216.7,1388.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2218.4,1384.4,2216.7,1386.1,2216.7,1388.2z" />
              <path className="st3" d="M2216.7,1411.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2218.4,1407.7,2216.7,1409.4,2216.7,1411.5z" />
              <path className="st3" d="M2193.4,1132.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2195.1,1128.2,2193.4,1129.9,2193.4,1132.1C2193.4,1132.1,2193.4,1132.1,2193.4,1132.1z" />
              <path className="st3" d="M2193.4,1155.4c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2195.1,1151.5,2193.4,1153.2,2193.4,1155.4C2193.4,1155.3,2193.4,1155.3,2193.4,1155.4z" />
              <path className="st3" d="M2193.4,1178.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2195.1,1174.8,2193.4,1176.5,2193.4,1178.6z" />
              <path className="st3" d="M2193.4,1201.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2195.1,1198.1,2193.4,1199.8,2193.4,1201.9z" />
              <path className="st3" d="M2193.4,1225.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2195.1,1221.4,2193.4,1223.1,2193.4,1225.2z" />
              <path className="st3" d="M2193.4,1248.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2195.1,1244.7,2193.4,1246.4,2193.4,1248.5z" />
              <path className="st3" d="M2193.4,1271.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2195.1,1267.9,2193.4,1269.6,2193.4,1271.8C2193.4,1271.8,2193.4,1271.8,2193.4,1271.8z" />
              <path className="st3" d="M2193.4,1295.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2195.1,1291.2,2193.4,1292.9,2193.4,1295.1z" />
              <path className="st3" d="M2193.4,1318.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2195.1,1314.5,2193.4,1316.2,2193.4,1318.3z" />
              <path className="st3" d="M2193.4,1341.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2195.1,1337.8,2193.4,1339.5,2193.4,1341.6z" />
              <path className="st3" d="M2193.4,1364.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8
						C2195.1,1361.1,2193.4,1362.8,2193.4,1364.9C2193.4,1364.9,2193.4,1364.9,2193.4,1364.9z" />
              <path className="st3" d="M2193.4,1388.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2195.1,1384.4,2193.4,1386.1,2193.4,1388.2C2193.4,1388.2,2193.4,1388.2,2193.4,1388.2z" />
              <path className="st3" d="M2193.4,1411.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2195.1,1407.7,2193.4,1409.4,2193.4,1411.5z" />
              <path className="st3" d="M2170.1,1132.1c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2171.8,1128.2,2170.1,1129.9,2170.1,1132.1C2170.1,1132.1,2170.1,1132.1,2170.1,1132.1z" />
              <path className="st3" d="M2170.1,1155.4c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2171.8,1151.5,2170.1,1153.2,2170.1,1155.4C2170.1,1155.3,2170.1,1155.3,2170.1,1155.4z" />
              <path className="st3" d="M2170.1,1178.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2171.8,1174.8,2170.1,1176.5,2170.1,1178.6z" />
              <path className="st3" d="M2170.1,1201.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2171.8,1198.1,2170.1,1199.8,2170.1,1201.9z" />
              <path className="st3" d="M2170.1,1225.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2171.8,1221.4,2170.1,1223.1,2170.1,1225.2z" />
              <path className="st3" d="M2170.1,1248.5c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2171.8,1244.7,2170.1,1246.4,2170.1,1248.5z" />
              <path className="st3" d="M2170.1,1271.8c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2171.8,1267.9,2170.1,1269.6,2170.1,1271.8C2170.1,1271.8,2170.1,1271.8,2170.1,1271.8z" />
              <path className="st3" d="M2170.1,1295.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2171.8,1291.2,2170.1,1292.9,2170.1,1295.1z" />
              <path className="st3" d="M2170.1,1318.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2171.8,1314.5,2170.1,1316.2,2170.1,1318.3z" />
              <path className="st3" d="M2170.1,1341.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2171.8,1337.8,2170.1,1339.5,2170.1,1341.6z" />
              <path className="st3" d="M2170.1,1364.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8
						C2171.8,1361.1,2170.1,1362.8,2170.1,1364.9C2170.1,1364.9,2170.1,1364.9,2170.1,1364.9z" />
              <path className="st3" d="M2170.1,1388.2c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2171.8,1384.4,2170.1,1386.1,2170.1,1388.2C2170.1,1388.2,2170.1,1388.2,2170.1,1388.2z" />
              <path className="st3" d="M2170.1,1411.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2171.8,1407.7,2170.1,1409.4,2170.1,1411.5z" />
              <path className="st3" d="M2146.8,1132.1c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2148.5,1128.3,2146.8,1130,2146.8,1132.1z" />
              <path className="st3" d="M2146.8,1155.4c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2148.5,1151.5,2146.8,1153.2,2146.8,1155.4z" />
              <path className="st3" d="M2146.8,1178.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2148.6,1174.8,2146.8,1176.5,2146.8,1178.6z" />
              <path className="st3" d="M2146.8,1201.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2148.6,1198.1,2146.8,1199.8,2146.8,1201.9z" />
              <path className="st3" d="M2146.8,1225.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2148.6,1221.4,2146.9,1223.1,2146.8,1225.2z" />
              <path className="st3" d="M2146.8,1248.5c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2148.5,1244.7,2146.8,1246.4,2146.8,1248.5z" />
              <path className="st3" d="M2146.8,1271.8c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2148.5,1268,2146.8,1269.7,2146.8,1271.8z" />
              <path className="st3" d="M2146.8,1295.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2148.6,1291.2,2146.8,1292.9,2146.8,1295.1z" />
              <path className="st3" d="M2146.8,1318.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2148.6,1314.5,2146.8,1316.2,2146.8,1318.3z" />
              <path className="st3" d="M2146.8,1341.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2148.6,1337.8,2146.9,1339.5,2146.8,1341.6z" />
              <path className="st3" d="M2146.8,1364.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8
						C2148.5,1361.1,2146.8,1362.8,2146.8,1364.9z" />
              <path className="st3" d="M2146.8,1388.2c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2148.5,1384.4,2146.8,1386.1,2146.8,1388.2z" />
              <path className="st3" d="M2146.8,1411.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2148.6,1407.7,2146.8,1409.4,2146.8,1411.5z" />
              <path className="st3" d="M2123.6,1132.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2125.3,1128.2,2123.6,1130,2123.6,1132.1z" />
              <path className="st3" d="M2123.6,1155.4c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2125.3,1151.5,2123.6,1153.2,2123.6,1155.4z" />
              <path className="st3" d="M2123.6,1178.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2125.3,1174.8,2123.6,1176.5,2123.6,1178.6z" />
              <path className="st3" d="M2123.6,1201.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2125.3,1198.1,2123.6,1199.8,2123.6,1201.9z" />
              <path className="st3" d="M2123.6,1225.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2125.3,1221.4,2123.6,1223.1,2123.6,1225.2z" />
              <path className="st3" d="M2123.6,1248.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2125.3,1244.7,2123.6,1246.4,2123.6,1248.5z" />
              <path className="st3" d="M2123.6,1271.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2125.3,1267.9,2123.6,1269.7,2123.6,1271.8z" />
              <path className="st3" d="M2123.6,1295.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2125.3,1291.2,2123.6,1292.9,2123.6,1295.1z" />
              <path className="st3" d="M2123.6,1318.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2125.3,1314.5,2123.6,1316.2,2123.6,1318.3z" />
              <path className="st3" d="M2123.6,1341.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2125.3,1337.8,2123.6,1339.5,2123.6,1341.6z" />
              <path className="st3" d="M2123.6,1364.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2125.3,1361.1,2123.6,1362.8,2123.6,1364.9z" />
              <path className="st3" d="M2123.6,1388.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2125.3,1384.4,2123.6,1386.1,2123.6,1388.2z" />
              <path className="st3" d="M2123.6,1411.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2125.3,1407.7,2123.6,1409.4,2123.6,1411.5z" />
              <path className="st3" d="M2100.3,1155.4c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2102,1151.5,2100.3,1153.2,2100.3,1155.4z" />
              <path className="st3" d="M2100.3,1178.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2102,1174.8,2100.3,1176.5,2100.3,1178.6z" />
              <path className="st3" d="M2100.3,1201.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8
						C2102,1198.1,2100.3,1199.8,2100.3,1201.9C2100.3,1201.9,2100.3,1201.9,2100.3,1201.9z" />
              <path className="st3" d="M2100.3,1225.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2102,1221.4,2100.3,1223.1,2100.3,1225.2z" />
              <path className="st3" d="M2100.3,1248.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2102,1244.7,2100.3,1246.4,2100.3,1248.5z" />
              <path className="st3" d="M2100.3,1271.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2102,1267.9,2100.3,1269.7,2100.3,1271.8z" />
              <path className="st3" d="M2100.3,1295.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2102,1291.2,2100.3,1292.9,2100.3,1295.1z" />
              <path className="st3" d="M2100.3,1318.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8
						C2102,1314.5,2100.3,1316.2,2100.3,1318.3z" />
              <path className="st3" d="M2100.3,1341.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2102,1337.8,2100.3,1339.5,2100.3,1341.6z" />
              <path className="st3" d="M2100.3,1364.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2102,1361.1,2100.3,1362.8,2100.3,1364.9z" />
              <path className="st3" d="M2100.3,1388.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8l0,0
						C2102,1384.4,2100.3,1386.1,2100.3,1388.2z" />
              <path className="st3" d="M2077,1155.4c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2078.8,1151.5,2077,1153.2,2077,1155.4z" />
              <path className="st3" d="M2077,1178.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2078.8,1174.8,2077,1176.5,2077,1178.6z" />
              <path className="st3" d="M2077,1201.9c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8c0,0,0,0,0,0c0-2.1-1.7-3.8-3.8-3.8
						C2078.8,1198.1,2077.1,1199.8,2077,1201.9C2077,1201.9,2077,1201.9,2077,1201.9z" />
              <path className="st3" d="M2077,1225.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2078.8,1221.4,2077,1223.1,2077,1225.2z" />
              <path className="st3" d="M2077,1248.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2078.8,1244.7,2077,1246.4,2077,1248.5z" />
              <path className="st3" d="M2077,1271.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2078.8,1267.9,2077,1269.7,2077,1271.8z" />
              <path className="st3" d="M2077,1295.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2078.8,1291.2,2077,1292.9,2077,1295.1z" />
              <path className="st3" d="M2077,1318.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8
						C2078.8,1314.5,2077.1,1316.2,2077,1318.3z" />
              <path className="st3" d="M2077,1341.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2078.8,1337.8,2077,1339.5,2077,1341.6z" />
              <path className="st3" d="M2077,1364.9c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2078.8,1361.1,2077,1362.8,2077,1364.9z" />
              <path className="st3" d="M2077,1388.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2078.8,1384.4,2077,1386.1,2077,1388.2z" />
              <path className="st3" d="M2053.7,1178.6c0,2.1,1.7,3.9,3.8,3.9s3.9-1.7,3.9-3.8c0-2.1-1.7-3.9-3.8-3.9c0,0-0.1,0-0.1,0
						C2055.4,1174.8,2053.7,1176.5,2053.7,1178.6z" />
              <path className="st3" d="M2053.7,1201.9c0,2.1,1.7,3.9,3.8,3.9c2.1,0,3.9-1.7,3.9-3.8c0-2.1-1.7-3.9-3.8-3.9c0,0-0.1,0-0.1,0
						C2055.4,1198.1,2053.7,1199.8,2053.7,1201.9z" />
              <path className="st3" d="M2053.7,1225.2c0,2.1,1.7,3.9,3.8,3.9c2.1,0,3.9-1.7,3.9-3.8c0-2.1-1.7-3.9-3.8-3.9c0,0-0.1,0-0.1,0
						C2055.4,1221.4,2053.7,1223.1,2053.7,1225.2z" />
              <path className="st3" d="M2053.7,1248.5c0,2.1,1.7,3.9,3.8,3.9s3.9-1.7,3.9-3.8c0-2.1-1.7-3.9-3.8-3.9c0,0-0.1,0-0.1,0
						C2055.4,1244.7,2053.7,1246.4,2053.7,1248.5z" />
              <path className="st3" d="M2053.7,1271.8c0,2.1,1.7,3.9,3.8,3.9c2.1,0,3.9-1.7,3.9-3.8c0-2.1-1.7-3.9-3.8-3.9c0,0-0.1,0-0.1,0
						C2055.4,1267.9,2053.7,1269.7,2053.7,1271.8z" />
              <path className="st3" d="M2053.7,1295.1c0,2.1,1.7,3.9,3.8,3.9c2.1,0,3.9-1.7,3.9-3.8c0-2.1-1.7-3.9-3.8-3.9c0,0-0.1,0-0.1,0
						C2055.4,1291.2,2053.7,1292.9,2053.7,1295.1z" />
              <path className="st3" d="M2053.7,1318.3c0,2.1,1.7,3.9,3.8,3.9s3.9-1.7,3.9-3.8c0-2.1-1.7-3.9-3.8-3.9c0,0-0.1,0-0.1,0
						C2055.4,1314.5,2053.7,1316.2,2053.7,1318.3z" />
              <path className="st3" d="M2053.7,1341.6c0,2.1,1.7,3.9,3.8,3.9c2.1,0,3.9-1.7,3.9-3.8c0-2.1-1.7-3.9-3.8-3.9c0,0-0.1,0-0.1,0
						C2055.4,1337.8,2053.7,1339.5,2053.7,1341.6z" />
              <path className="st3" d="M2053.7,1364.9c0,2.1,1.7,3.9,3.8,3.9c2.1,0,3.9-1.7,3.9-3.8c0-2.1-1.7-3.9-3.8-3.9c0,0-0.1,0-0.1,0
						C2055.4,1361.1,2053.7,1362.8,2053.7,1364.9z" />
              <path className="st3" d="M2030.4,1225.2c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2032.1,1221.4,2030.4,1223.1,2030.4,1225.2z" />
              <path className="st3" d="M2030.4,1248.5c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8
						C2032.1,1244.6,2030.4,1246.4,2030.4,1248.5C2030.4,1248.5,2030.4,1248.5,2030.4,1248.5z" />
              <path className="st3" d="M2030.4,1271.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8
						C2032.1,1268,2030.4,1269.7,2030.4,1271.8z" />
              <path className="st3" d="M2030.4,1295.1c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2032.1,1291.2,2030.4,1292.9,2030.4,1295.1z" />
              <path className="st3" d="M2030.4,1318.3c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2032.1,1314.5,2030.4,1316.2,2030.4,1318.3z" />
              <path className="st3" d="M2030.4,1341.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8l0,0
						C2032.1,1337.8,2030.4,1339.5,2030.4,1341.6z" />
            </g>
          </g>
          <g>
            <defs>
              <path id="SVGID_9_" d="M293.7,710.3c0,61.1-49.5,110.6-110.6,110.6S72.5,771.4,72.5,710.3c0-61.1,49.5-110.6,110.6-110.6
						S293.7,649.2,293.7,710.3L293.7,710.3z" />
            </defs>
            <clipPath id="SVGID_10_">
              <use xlinkHref="#SVGID_9_" style={{overflow: 'visible'}} />
            </clipPath>
            <g className="st8">
              <path className="st3" d="M287.5,705.6c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7s-2.6-5.7-5.7-5.7c0,0-0.1,0-0.1,0
						C290,699.9,287.5,702.5,287.5,705.6z" />
              <path className="st3" d="M287.5,740.4c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7c0,0-0.1,0-0.1,0
						C290,734.8,287.5,737.3,287.5,740.4z" />
              <path className="st3" d="M252.7,635.9c0,3.2,2.5,5.8,5.7,5.8s5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0,0,0,0,0
						C255.3,630.2,252.7,632.7,252.7,635.9z" />
              <path className="st3" d="M252.7,670.8c0,3.2,2.5,5.8,5.7,5.8s5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0,0,0,0,0
						C255.3,665,252.7,667.6,252.7,670.8z" />
              <path className="st3" d="M252.7,705.6c0,3.2,2.5,5.8,5.7,5.8s5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0,0,0,0,0
						C255.3,699.9,252.7,702.4,252.7,705.6z" />
              <path className="st3" d="M252.7,740.4c0,3.2,2.5,5.8,5.7,5.8c3.2,0,5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0,0,0,0,0
						C255.3,734.7,252.7,737.3,252.7,740.4z" />
              <path className="st3" d="M252.7,775.4c0,3.2,2.5,5.8,5.7,5.8c3.2,0,5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0,0,0,0,0
						C255.3,769.6,252.7,772.2,252.7,775.4z" />
              <path className="st3" d="M217.8,635.9c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7s-2.6-5.7-5.7-5.7l0,0
						C220.4,630.2,217.8,632.8,217.8,635.9z" />
              <path className="st3" d="M217.8,670.8c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7l0,0
						C220.4,665,217.8,667.6,217.8,670.8z" />
              <path className="st3" d="M217.8,705.6c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7l0,0
						C220.4,699.9,217.9,702.4,217.8,705.6z" />
              <path className="st3" d="M217.8,740.4c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7l0,0
						C220.4,734.7,217.8,737.3,217.8,740.4z" />
              <path className="st3" d="M217.8,775.4c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7l0,0
						C220.4,769.7,217.9,772.2,217.8,775.4z" />
              <path className="st3" d="M217.8,810.1c0.1,3.2,2.7,5.7,5.9,5.6c3.2-0.1,5.7-2.7,5.6-5.9c-0.1-3.1-2.6-5.6-5.7-5.6
						c-3.2,0-5.7,2.6-5.7,5.7C217.8,810,217.8,810,217.8,810.1z" />
              <path className="st3" d="M183,601.1c0,3.2,2.6,5.8,5.8,5.8c3.2,0,5.8-2.6,5.8-5.8c0-3.2-2.6-5.8-5.8-5.8h0
						C185.6,595.3,183,597.9,183,601.1z" />
              <path className="st3" d="M183,635.9c0,3.2,2.5,5.8,5.7,5.8c3.2,0,5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0,0,0,0,0
						C185.6,630.2,183,632.7,183,635.9z" />
              <path className="st3" d="M183,670.8c0,3.2,2.5,5.8,5.7,5.8c3.2,0,5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0,0,0,0,0
						C185.6,665,183,667.6,183,670.8z" />
              <path className="st3" d="M183,705.6c0,3.2,2.5,5.8,5.7,5.8c3.2,0,5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0,0,0,0,0
						C185.6,699.9,183,702.4,183,705.6z" />
              <path className="st3" d="M183,740.4c0,3.2,2.5,5.8,5.7,5.8c3.2,0,5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0,0,0,0,0
						C185.6,734.7,183,737.3,183,740.4z" />
              <path className="st3" d="M183,775.4c0,3.2,2.5,5.8,5.7,5.8c3.2,0,5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0,0,0,0,0
						C185.6,769.6,183,772.2,183,775.4z" />
              <path className="st3" d="M183,810.1c0,3.2,2.5,5.8,5.7,5.8c3.2,0,5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0,0,0,0,0
						C185.6,804.4,183,806.9,183,810.1z" />
              <path className="st3" d="M148.2,601.1c0,3.2,2.6,5.7,5.8,5.7c3.2,0,5.7-2.6,5.7-5.8c0-3.1-2.6-5.7-5.7-5.7
						C150.7,595.3,148.2,597.9,148.2,601.1C148.2,601.1,148.2,601.1,148.2,601.1z" />
              <path className="st3" d="M148.2,635.9c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7s-2.6-5.7-5.7-5.7l0,0
						C150.7,630.2,148.2,632.8,148.2,635.9z" />
              <path className="st3" d="M148.2,670.8c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7l0,0
						C150.7,665,148.2,667.6,148.2,670.8z" />
              <path className="st3" d="M148.2,705.6c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7l0,0
						C150.8,699.9,148.2,702.4,148.2,705.6z" />
              <path className="st3" d="M148.2,740.4c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7l0,0
						C150.7,734.7,148.2,737.3,148.2,740.4z" />
              <path className="st3" d="M148.2,775.4c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7l0,0
						C150.8,769.7,148.2,772.2,148.2,775.4z" />
              <path className="st3" d="M148.2,810.1c0.1,3.2,2.7,5.7,5.9,5.6c3.2-0.1,5.7-2.7,5.6-5.9c-0.1-3.1-2.6-5.6-5.7-5.6
						c-3.2,0-5.7,2.6-5.7,5.7C148.2,810,148.2,810,148.2,810.1z" />
              <path className="st3" d="M113.4,635.9c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7s-2.6-5.7-5.7-5.7
						C115.9,630.2,113.4,632.8,113.4,635.9L113.4,635.9z" />
              <path className="st3" d="M113.4,670.8c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7
						C115.9,665,113.4,667.6,113.4,670.8L113.4,670.8z" />
              <path className="st3" d="M113.4,705.6c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7
						C115.9,699.9,113.4,702.4,113.4,705.6z" />
              <path className="st3" d="M113.4,740.4c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7
						C115.9,734.7,113.4,737.3,113.4,740.4L113.4,740.4z" />
              <path className="st3" d="M113.4,775.4c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7
						C115.9,769.7,113.4,772.2,113.4,775.4z" />
              <path className="st3" d="M78.5,670.8c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7c0,0,0,0,0,0
						C81.1,665,78.5,667.6,78.5,670.8z" />
              <path className="st3" d="M78.5,705.6c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7c0,0,0,0,0,0
						C81.1,699.9,78.5,702.4,78.5,705.6z" />
              <path className="st3" d="M78.5,740.4c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7c0,0,0,0,0,0
						C81.1,734.7,78.5,737.3,78.5,740.4z" />
            </g>
          </g>
          <g>
            <path className="st3" d="M2964.5,9.8c0,3.2,2.6,5.7,5.8,5.6c3.2,0,5.7-2.6,5.6-5.8c0-3.1-2.6-5.6-5.7-5.6
					C2967.1,3.9,2964.5,6.5,2964.5,9.8C2964.5,9.7,2964.5,9.7,2964.5,9.8z" />
            <path className="st3" d="M2964.5,44.6c0,3.2,2.6,5.7,5.8,5.7c3.2,0,5.7-2.6,5.7-5.8c0-3.1-2.6-5.7-5.7-5.7
					C2967.1,38.8,2964.5,41.4,2964.5,44.6C2964.5,44.6,2964.5,44.6,2964.5,44.6z" />
            <path className="st3" d="M2964.5,79.4c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7S2964.5,76.3,2964.5,79.4
					C2964.5,79.4,2964.5,79.4,2964.5,79.4z" />
            <path className="st3" d="M2964.5,114.3c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7
					C2967.1,108.5,2964.5,111.1,2964.5,114.3C2964.5,114.3,2964.5,114.3,2964.5,114.3z" />
            <path className="st3" d="M2929.7,9.8c0,3.2,2.6,5.7,5.8,5.6c3.2,0,5.7-2.6,5.6-5.8c0-3.1-2.6-5.6-5.7-5.6
					C2932.2,3.9,2929.7,6.5,2929.7,9.8C2929.7,9.7,2929.7,9.7,2929.7,9.8z" />
            <path className="st3" d="M2929.7,44.6c0,3.2,2.6,5.7,5.8,5.7c3.2,0,5.7-2.6,5.7-5.8c0-3.1-2.6-5.7-5.7-5.7
					C2932.2,38.8,2929.7,41.4,2929.7,44.6C2929.7,44.6,2929.7,44.6,2929.7,44.6z" />
            <path className="st3" d="M2929.7,79.4c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7S2929.7,76.3,2929.7,79.4
					C2929.7,79.4,2929.7,79.4,2929.7,79.4z" />
            <path className="st3" d="M2929.7,114.3c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7
					C2932.2,108.5,2929.7,111.1,2929.7,114.3C2929.7,114.3,2929.7,114.3,2929.7,114.3z" />
            <path className="st3" d="M2894.8,9.8c0,3.2,2.6,5.7,5.8,5.7s5.7-2.6,5.7-5.8c0-3.2-2.6-5.7-5.8-5.7
					C2897.4,3.9,2894.8,6.5,2894.8,9.8C2894.8,9.7,2894.8,9.7,2894.8,9.8z" />
            <path className="st3" d="M2894.8,44.6c0.3,3.2,3.1,5.5,6.2,5.3c2.8-0.2,5-2.5,5.3-5.3c0.3-3.2-2.1-6-5.3-6.2c-3.2-0.3-6,2.1-6.2,5.3
					C2894.8,44,2894.8,44.3,2894.8,44.6z" />
            <path className="st3" d="M2894.8,79.4c0,3.2,2.5,5.8,5.7,5.8s5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0-0.1,0-0.1,0
					C2897.4,73.7,2894.8,76.3,2894.8,79.4C2894.8,79.4,2894.8,79.4,2894.8,79.4z" />
            <path className="st3" d="M2894.8,114.3c0,3.2,2.5,5.8,5.7,5.8c3.2,0,5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0-0.1,0-0.1,0
					C2897.4,108.5,2894.8,111.1,2894.8,114.3C2894.8,114.3,2894.8,114.3,2894.8,114.3z" />
            <path className="st3" d="M2894.8,149.1c0,3.2,2.5,5.8,5.7,5.8s5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0-0.1,0-0.1,0
					C2897.4,143.4,2894.9,145.9,2894.8,149.1z" />
            <path className="st3" d="M2860,9.8c0,3.2,2.6,5.7,5.8,5.6c3.2,0,5.7-2.6,5.6-5.8c0-3.1-2.6-5.6-5.7-5.6
					C2862.6,3.9,2860,6.5,2860,9.8C2860,9.7,2860,9.7,2860,9.8z" />
            <path className="st3" d="M2860,44.6c0,3.2,2.6,5.7,5.8,5.7c3.2,0,5.7-2.6,5.7-5.8c0-3.1-2.6-5.7-5.7-5.7
					C2862.6,38.8,2860,41.4,2860,44.6C2860,44.6,2860,44.6,2860,44.6z" />
            <path className="st3" d="M2860,79.4c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7S2860,76.3,2860,79.4
					C2860,79.4,2860,79.4,2860,79.4z" />
            <path className="st3" d="M2860,114.3c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7
					C2862.6,108.5,2860,111.1,2860,114.3C2860,114.3,2860,114.3,2860,114.3z" />
            <path className="st3" d="M2860,149.1c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7
					C2862.6,143.4,2860,145.9,2860,149.1z" />
            <path className="st3" d="M2825.2,9.8c0,3.2,2.6,5.7,5.8,5.7s5.7-2.6,5.7-5.8c0-3.2-2.6-5.7-5.8-5.7
					C2827.7,3.9,2825.2,6.5,2825.2,9.8C2825.2,9.7,2825.2,9.7,2825.2,9.8z" />
            <path className="st3" d="M2825.2,44.6c0,3.2,2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8c0-3.2-2.6-5.8-5.8-5.8c0,0,0,0,0,0
					C2827.7,38.8,2825.2,41.4,2825.2,44.6C2825.2,44.6,2825.2,44.6,2825.2,44.6z" />
            <path className="st3" d="M2825.2,79.4c0,3.2,2.5,5.8,5.7,5.8s5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0-0.1,0-0.1,0
					C2827.7,73.7,2825.2,76.3,2825.2,79.4C2825.2,79.4,2825.2,79.4,2825.2,79.4z" />
            <path className="st3" d="M2825.2,114.3c0,3.2,2.5,5.8,5.7,5.8c3.2,0,5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8c0,0-0.1,0-0.1,0
					C2827.7,108.5,2825.2,111.1,2825.2,114.3C2825.2,114.3,2825.2,114.3,2825.2,114.3z" />
            <path className="st3" d="M2790.3,44.6c0,3.2,2.6,5.7,5.8,5.7c3.2,0,5.7-2.6,5.7-5.8c0-3.1-2.6-5.7-5.7-5.7
					C2792.9,38.8,2790.3,41.4,2790.3,44.6C2790.3,44.6,2790.3,44.6,2790.3,44.6z" />
            <path className="st3" d="M2790.3,79.4c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7S2790.3,76.3,2790.3,79.4
					C2790.3,79.4,2790.3,79.4,2790.3,79.4z" />
            <path className="st3" d="M2790.3,9.8c0,3.2,2.6,5.7,5.8,5.6c3.2,0,5.7-2.6,5.6-5.8c0-3.1-2.6-5.6-5.7-5.6
					C2792.9,3.9,2790.3,6.5,2790.3,9.8C2790.3,9.7,2790.3,9.7,2790.3,9.8z" />
            <path className="st3" d="M2929.7,149.1c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7
					C2932.3,143.4,2929.7,145.9,2929.7,149.1z" />
            <path className="st3" d="M2999.4,79.4c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7c0-3.1-2.5-5.6-5.6-5.7c-3.2-0.1-5.8,2.4-5.9,5.6
					C2999.4,79.3,2999.4,79.4,2999.4,79.4z" />
            <path className="st3" d="M2999.4,44.6c0,3.2,2.6,5.7,5.8,5.7c3.2,0,5.7-2.6,5.7-5.8c0-3.1-2.6-5.7-5.7-5.7
					C3001.9,38.8,2999.4,41.4,2999.4,44.6C2999.4,44.6,2999.4,44.6,2999.4,44.6z" />
            <path className="st9" d="M2798.1-4.3c-29.6,53.5-10.2,120.8,43.3,150.3s120.8,10.2,150.3-43.3c18.4-33.3,18.4-73.7,0-107H2798.1z" />
          </g>
          <g>
            <defs>
              <path id="SVGID_11_" d="M2280.8,545.2c0,49.8-40.4,90.2-90.2,90.2s-90.2-40.4-90.2-90.2s40.4-90.2,90.2-90.2
						S2280.8,495.4,2280.8,545.2L2280.8,545.2z" />
            </defs>
            <clipPath id="SVGID_12_">
              <use xlinkHref="#SVGID_11_" style={{overflow: 'visible'}} />
            </clipPath>
            <g className="st10">
              <path className="st3" d="M2275.7,541.4c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
						C2277.8,536.7,2275.7,538.8,2275.7,541.4C2275.7,541.4,2275.7,541.4,2275.7,541.4z" />
              <path className="st3" d="M2275.7,569.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0-0.1,0-0.1,0
						C2277.8,565.2,2275.8,567.2,2275.7,569.8z" />
              <path className="st3" d="M2247.4,484.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0-0.1,0-0.1,0
						C2249.4,479.9,2247.4,482,2247.4,484.6L2247.4,484.6z" />
              <path className="st3" d="M2247.4,513c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
						C2249.4,508.3,2247.4,510.4,2247.4,513C2247.4,513,2247.4,513,2247.4,513z" />
              <path className="st3" d="M2247.4,541.4c0.2,2.6,2.5,4.5,5.1,4.2c2.2-0.2,4-2,4.2-4.2c0.2-2.6-1.7-4.9-4.2-5.1
						c-2.6-0.2-4.9,1.7-5.1,4.2C2247.3,540.8,2247.3,541.1,2247.4,541.4z" />
              <path className="st3" d="M2247.4,569.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0-0.1,0-0.1,0
						C2249.4,565.1,2247.4,567.2,2247.4,569.8C2247.4,569.8,2247.4,569.8,2247.4,569.8z" />
              <path className="st3" d="M2247.4,598.2c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
						C2249.4,593.5,2247.4,595.6,2247.4,598.2C2247.4,598.2,2247.4,598.2,2247.4,598.2z" />
              <path className="st3" d="M2218.9,456.2c-0.2,2.6,1.7,4.9,4.2,5.1c2.6,0.2,4.9-1.7,5.1-4.2c0-0.3,0-0.6,0-0.9
						c-0.2-2.6-2.5-4.5-5.1-4.2C2220.9,452.2,2219.1,454,2218.9,456.2z" />
              <path className="st3" d="M2218.9,484.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
						C2221.1,479.9,2219,482,2218.9,484.6z" />
              <path className="st3" d="M2218.9,513c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2218.9,510.4,2218.9,513
						C2218.9,513,2218.9,513,2218.9,513z" />
              <path className="st3" d="M2218.9,541.4c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
						C2221.1,536.7,2219,538.7,2218.9,541.4C2218.9,541.3,2218.9,541.4,2218.9,541.4z" />
              <path className="st3" d="M2218.9,569.8c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
						C2221.1,565.1,2219,567.2,2218.9,569.8z" />
              <path className="st3" d="M2218.9,598.2c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
						S2218.9,595.6,2218.9,598.2L2218.9,598.2z" />
              <path className="st3" d="M2218.9,626.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
						C2221.1,621.9,2219,623.9,2218.9,626.6C2218.9,626.5,2218.9,626.6,2218.9,626.6z" />
              <path className="st3" d="M2190.5,456.2c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
						C2192.7,451.5,2190.6,453.6,2190.5,456.2z" />
              <path className="st3" d="M2190.5,484.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
						C2192.7,479.9,2190.6,482,2190.5,484.6z" />
              <path className="st3" d="M2190.5,513c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
						C2192.7,508.3,2190.5,510.4,2190.5,513C2190.5,513,2190.5,513,2190.5,513z" />
              <path className="st3" d="M2190.5,541.4c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
						C2192.7,536.7,2190.6,538.7,2190.5,541.4C2190.5,541.3,2190.5,541.4,2190.5,541.4z" />
              <path className="st3" d="M2190.5,569.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7s-2.1-4.7-4.7-4.7c0,0,0,0,0,0
						C2192.7,565.1,2190.6,567.2,2190.5,569.8z" />
              <path className="st3" d="M2190.5,598.2c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
						C2192.7,593.5,2190.5,595.6,2190.5,598.2L2190.5,598.2z" />
              <path className="st3" d="M2190.5,626.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
						C2192.7,621.9,2190.6,623.9,2190.5,626.6C2190.5,626.5,2190.5,626.6,2190.5,626.6z" />
              <path className="st3" d="M2162.2,456.2c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0-0.1,0-0.1,0
						C2164.3,451.6,2162.2,453.6,2162.2,456.2z" />
              <path className="st3" d="M2162.2,484.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0-0.1,0-0.1,0
						C2164.3,480,2162.2,482,2162.2,484.6z" />
              <path className="st3" d="M2162.2,513c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
						C2164.2,508.3,2162.2,510.4,2162.2,513z" />
              <path className="st3" d="M2162.2,541.4c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
						C2164.2,536.7,2162.2,538.8,2162.2,541.4C2162.2,541.4,2162.2,541.4,2162.2,541.4z" />
              <path className="st3" d="M2162.2,569.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0-0.1,0-0.1,0
						C2164.3,565.2,2162.2,567.2,2162.2,569.8z" />
              <path className="st3" d="M2162.2,598.2c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
						C2164.2,593.5,2162.2,595.6,2162.2,598.2z" />
              <path className="st3" d="M2162.2,626.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
						C2164.2,621.9,2162.2,624,2162.2,626.6C2162.2,626.6,2162.2,626.6,2162.2,626.6z" />
              <path className="st3" d="M2133.8,484.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0-0.1,0-0.1,0
						C2135.9,479.9,2133.8,482,2133.8,484.6L2133.8,484.6z" />
              <path className="st3" d="M2133.8,513c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
						C2135.9,508.3,2133.8,510.4,2133.8,513C2133.8,513,2133.8,513,2133.8,513z" />
              <path className="st3" d="M2133.8,541.4c0.2,2.6,2.5,4.5,5.1,4.2c2.2-0.2,4-2,4.2-4.2c0.2-2.6-1.7-4.9-4.2-5.1
						c-2.6-0.2-4.9,1.7-5.1,4.2C2133.7,540.8,2133.7,541.1,2133.8,541.4z" />
              <path className="st3" d="M2133.8,569.8c-0.1,2.6,1.9,4.8,4.5,4.9c2.6,0.1,4.8-1.9,4.9-4.5c0.1-2.6-1.9-4.8-4.5-4.9
						c-0.1,0-0.1,0-0.2,0C2135.9,565.3,2133.8,567.3,2133.8,569.8z" />
              <path className="st3" d="M2133.8,598.2c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
						C2135.9,593.5,2133.8,595.6,2133.8,598.2C2133.8,598.2,2133.8,598.2,2133.8,598.2z" />
              <path className="st3" d="M2105.3,513c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2105.3,510.4,2105.3,513
						C2105.3,513,2105.3,513,2105.3,513z" />
              <path className="st3" d="M2105.3,541.4c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
						C2107.5,536.7,2105.4,538.7,2105.3,541.4C2105.3,541.3,2105.3,541.4,2105.3,541.4z" />
              <path className="st3" d="M2105.3,569.8c-0.1,2.6,1.9,4.8,4.5,4.9c2.6,0.1,4.8-1.9,4.9-4.5c0.1-2.6-1.9-4.8-4.5-4.9
						c-0.1,0-0.1,0-0.2,0C2107.5,565.3,2105.4,567.3,2105.3,569.8z" />
            </g>
          </g>
          <g className="st11">
            <g>
              <defs>
                <path id="SVGID_13_" d="M3013.5,1760.9c0,106.7-86.4,193.2-193.1,193.2c-106.7,0-193.2-86.4-193.2-193.1
							c0-106.7,86.4-193.2,193.1-193.2c0,0,0,0,0,0C2927,1567.8,3013.5,1654.2,3013.5,1760.9z" />
              </defs>
              <clipPath id="SVGID_14_">
                <use xlinkHref="#SVGID_13_" style={{overflow: 'visible'}} />
              </clipPath>
              <g className="st12">
                <path className="st3" d="M2798,1780.2c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2798,1777.6,2798,1780.2
							L2798,1780.2z" />
                <path className="st3" d="M2798,1808.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2800.1,1804,2798,1806.1,2798,1808.8C2798,1808.7,2798,1808.8,2798,1808.8z" />
                <path className="st3" d="M2798,1837.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2798,1834.7,2798,1837.3
							L2798,1837.3z" />
                <path className="st3" d="M2798,1865.8c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2798,1863.2,2798,1865.8
							L2798,1865.8z" />
                <path className="st3" d="M2798,1894.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2798,1891.7,2798,1894.3
							L2798,1894.3z" />
                <path className="st3" d="M2798,1922.8c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2798,1920.2,2798,1922.8
							L2798,1922.8z" />
                <path className="st3" d="M2798,1951.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2798,1948.7,2798,1951.3
							L2798,1951.3z" />
                <path className="st3" d="M2769.6,1780.2c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2769.6,1777.6,2769.6,1780.2L2769.6,1780.2z" />
                <path className="st3" d="M2769.6,1808.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2771.7,1804,2769.6,1806.1,2769.6,1808.8C2769.6,1808.7,2769.6,1808.8,2769.6,1808.8z" />
                <path className="st3" d="M2769.6,1837.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2769.6,1834.7,2769.6,1837.3L2769.6,1837.3z" />
                <path className="st3" d="M2769.6,1865.8c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2769.6,1863.2,2769.6,1865.8L2769.6,1865.8z" />
                <path className="st3" d="M2769.6,1894.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2769.6,1891.7,2769.6,1894.3L2769.6,1894.3z" />
                <path className="st3" d="M2769.6,1922.8c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2769.6,1920.2,2769.6,1922.8L2769.6,1922.8z" />
                <path className="st3" d="M2769.6,1951.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2769.6,1948.7,2769.6,1951.3L2769.6,1951.3z" />
                <path className="st3" d="M2741.1,1780.2c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2741.1,1777.6,2741.1,1780.2L2741.1,1780.2z" />
                <path className="st3" d="M2741.1,1808.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2743.2,1804,2741.1,1806.1,2741.1,1808.8C2741.1,1808.7,2741.1,1808.8,2741.1,1808.8z" />
                <path className="st3" d="M2741.1,1837.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2741.1,1834.7,2741.1,1837.3L2741.1,1837.3z" />
                <path className="st3" d="M2741.1,1865.8c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2741.1,1863.2,2741.1,1865.8L2741.1,1865.8z" />
                <path className="st3" d="M2741.1,1894.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2741.1,1891.7,2741.1,1894.3L2741.1,1894.3z" />
                <path className="st3" d="M2741.1,1922.8c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2741.1,1920.2,2741.1,1922.8L2741.1,1922.8z" />
                <path className="st3" d="M2712.5,1780.2c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2714.6,1775.5,2712.5,1777.6,2712.5,1780.2C2712.5,1780.2,2712.5,1780.2,2712.5,1780.2z" />
                <path className="st3" d="M2712.5,1808.8c0.2,2.6,2.5,4.5,5.1,4.2c2.2-0.2,4-2,4.2-4.2c0.2-2.6-1.7-4.9-4.2-5.1
							c-2.6-0.2-4.9,1.7-5.1,4.2C2712.5,1808.2,2712.5,1808.5,2712.5,1808.8z" />
                <path className="st3" d="M2712.5,1837.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2714.6,1832.6,2712.5,1834.7,2712.5,1837.3C2712.5,1837.3,2712.5,1837.3,2712.5,1837.3z" />
                <path className="st3" d="M2712.5,1865.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2714.6,1861.1,2712.5,1863.2,2712.5,1865.8C2712.5,1865.8,2712.5,1865.8,2712.5,1865.8z" />
                <path className="st3" d="M2712.5,1894.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2714.6,1889.6,2712.5,1891.7,2712.5,1894.3C2712.5,1894.3,2712.5,1894.3,2712.5,1894.3z" />
                <path className="st3" d="M2712.5,1922.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2714.6,1918.1,2712.5,1920.2,2712.5,1922.8C2712.5,1922.8,2712.5,1922.8,2712.5,1922.8z" />
                <path className="st3" d="M2684,1780.2c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2684,1777.6,2684,1780.2
							L2684,1780.2z" />
                <path className="st3" d="M2684,1808.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2686.1,1804,2684,1806.1,2684,1808.8C2684,1808.7,2684,1808.8,2684,1808.8z" />
                <path className="st3" d="M2684,1837.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2684,1834.7,2684,1837.3
							L2684,1837.3z" />
                <path className="st3" d="M2684,1865.8c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2684,1863.2,2684,1865.8
							L2684,1865.8z" />
                <path className="st3" d="M2684,1894.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2684,1891.7,2684,1894.3
							L2684,1894.3z" />
                <path className="st3" d="M2655.5,1780.2c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2655.5,1777.6,2655.5,1780.2L2655.5,1780.2z" />
                <path className="st3" d="M2655.5,1808.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2657.6,1804,2655.5,1806.1,2655.5,1808.8C2655.5,1808.7,2655.5,1808.8,2655.5,1808.8z" />
                <path className="st3" d="M2655.5,1837.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2655.5,1834.7,2655.5,1837.3L2655.5,1837.3z" />
                <path className="st3" d="M2655.5,1865.8c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2655.5,1863.2,2655.5,1865.8L2655.5,1865.8z" />
                <path className="st3" d="M2627,1780.2c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2627,1777.6,2627,1780.2
							L2627,1780.2z" />
                <path className="st3" d="M2627,1808.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2629.1,1804,2627,1806.1,2627,1808.8C2627,1808.7,2627,1808.8,2627,1808.8z" />
                <path className="st3" d="M2798,1579.5c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2798,1576.9,2798,1579.5
							L2798,1579.5z" />
                <path className="st3" d="M2798,1608c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2798,1605.5,2798,1608
							L2798,1608z" />
                <path className="st3" d="M2798,1636.4c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2798,1633.8,2798,1636.4
							L2798,1636.4z" />
                <path className="st3" d="M2798,1665.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2798,1662.7,2798,1665.3
							L2798,1665.3z" />
                <path className="st3" d="M2798,1693.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2798,1691,2798,1693.6
							L2798,1693.6z" />
                <path className="st3" d="M2798,1722.1c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2798,1719.5,2798,1722.1
							L2798,1722.1z" />
                <path className="st3" d="M2798,1750.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2798,1748,2798,1750.6
							L2798,1750.6z" />
                <path className="st3" d="M2769.6,1579.5c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2769.6,1576.9,2769.6,1579.5L2769.6,1579.5z" />
                <path className="st3" d="M2769.6,1608c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2769.6,1605.5,2769.6,1608
							L2769.6,1608z" />
                <path className="st3" d="M2769.6,1636.4c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2769.6,1633.8,2769.6,1636.4L2769.6,1636.4z" />
                <path className="st3" d="M2769.6,1665.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2769.6,1662.7,2769.6,1665.3L2769.6,1665.3z" />
                <path className="st3" d="M2769.6,1693.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2769.6,1691,2769.6,1693.6
							L2769.6,1693.6z" />
                <path className="st3" d="M2769.6,1722.1c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2769.6,1719.5,2769.6,1722.1L2769.6,1722.1z" />
                <path className="st3" d="M2769.6,1750.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2769.6,1748,2769.6,1750.6
							L2769.6,1750.6z" />
                <path className="st3" d="M2741.1,1579.5c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2741.1,1576.9,2741.1,1579.5L2741.1,1579.5z" />
                <path className="st3" d="M2741.1,1608c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2741.1,1605.5,2741.1,1608
							L2741.1,1608z" />
                <path className="st3" d="M2741.1,1636.4c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2741.1,1633.8,2741.1,1636.4L2741.1,1636.4z" />
                <path className="st3" d="M2741.1,1665.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2741.1,1662.7,2741.1,1665.3L2741.1,1665.3z" />
                <path className="st3" d="M2741.1,1693.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2741.1,1691,2741.1,1693.6
							L2741.1,1693.6z" />
                <path className="st3" d="M2741.1,1722.1c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2741.1,1719.5,2741.1,1722.1L2741.1,1722.1z" />
                <path className="st3" d="M2741.1,1750.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2741.1,1748,2741.1,1750.6
							L2741.1,1750.6z" />
                <path className="st3" d="M2712.5,1608c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2714.6,1603.4,2712.5,1605.4,2712.5,1608C2712.5,1608,2712.5,1608,2712.5,1608z" />
                <path className="st3" d="M2712.5,1636.4c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2714.6,1631.7,2712.5,1633.8,2712.5,1636.4C2712.5,1636.4,2712.5,1636.4,2712.5,1636.4z" />
                <path className="st3" d="M2712.5,1665.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2714.6,1660.6,2712.5,1662.7,2712.5,1665.3C2712.5,1665.2,2712.5,1665.3,2712.5,1665.3z" />
                <path className="st3" d="M2712.5,1693.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2714.6,1688.9,2712.5,1691,2712.5,1693.6C2712.5,1693.6,2712.5,1693.6,2712.5,1693.6z" />
                <path className="st3" d="M2712.5,1722.1c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2714.6,1717.4,2712.5,1719.5,2712.5,1722.1C2712.5,1722.1,2712.5,1722.1,2712.5,1722.1z" />
                <path className="st3" d="M2712.5,1750.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2714.6,1745.9,2712.5,1748,2712.5,1750.6C2712.5,1750.6,2712.5,1750.6,2712.5,1750.6z" />
                <path className="st3" d="M2684,1636.4c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2684,1633.8,2684,1636.4
							L2684,1636.4z" />
                <path className="st3" d="M2684,1665.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2684,1662.7,2684,1665.3
							L2684,1665.3z" />
                <path className="st3" d="M2684,1693.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2684,1691,2684,1693.6
							L2684,1693.6z" />
                <path className="st3" d="M2684,1722.1c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2684,1719.5,2684,1722.1
							L2684,1722.1z" />
                <path className="st3" d="M2684,1750.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2684,1748,2684,1750.6
							L2684,1750.6z" />
                <path className="st3" d="M2655.5,1665.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2655.5,1662.7,2655.5,1665.3L2655.5,1665.3z" />
                <path className="st3" d="M2655.5,1693.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2655.5,1691,2655.5,1693.6
							L2655.5,1693.6z" />
                <path className="st3" d="M2655.5,1722.1c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2655.5,1719.5,2655.5,1722.1L2655.5,1722.1z" />
                <path className="st3" d="M2655.5,1750.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2655.5,1748,2655.5,1750.6
							L2655.5,1750.6z" />
                <path className="st3" d="M2627,1722.1c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2627,1719.5,2627,1722.1
							L2627,1722.1z" />
                <path className="st3" d="M2627,1750.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2627,1748,2627,1750.6
							L2627,1750.6z" />
                <path className="st3" d="M2997.8,1780.2c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2999.9,1775.5,2997.8,1777.6,2997.8,1780.2L2997.8,1780.2z" />
                <path className="st3" d="M2997.8,1808.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2999.9,1804,2997.8,1806.1,2997.8,1808.8C2997.8,1808.7,2997.8,1808.8,2997.8,1808.8z" />
                <path className="st3" d="M2997.8,1837.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2999.9,1832.6,2997.8,1834.7,2997.8,1837.3L2997.8,1837.3z" />
                <path className="st3" d="M2969.3,1780.2c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2971.4,1775.5,2969.3,1777.6,2969.3,1780.2C2969.3,1780.2,2969.3,1780.2,2969.3,1780.2z" />
                <path className="st3" d="M2969.3,1808.8c0.2,2.6,2.5,4.5,5.1,4.2c2.2-0.2,4-2,4.2-4.2c0.2-2.6-1.7-4.9-4.2-5.1
							c-2.6-0.2-4.9,1.7-5.1,4.2C2969.3,1808.2,2969.3,1808.5,2969.3,1808.8z" />
                <path className="st3" d="M2969.3,1837.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2971.4,1832.6,2969.3,1834.7,2969.3,1837.3C2969.3,1837.3,2969.3,1837.3,2969.3,1837.3z" />
                <path className="st3" d="M2969.3,1865.8c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2971.4,1861.1,2969.3,1863.2,2969.3,1865.8C2969.3,1865.8,2969.3,1865.8,2969.3,1865.8z" />
                <path className="st3" d="M2940.7,1780.2c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2942.8,1775.5,2940.7,1777.6,2940.7,1780.2L2940.7,1780.2z" />
                <path className="st3" d="M2940.7,1808.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2942.8,1804,2940.7,1806.1,2940.7,1808.8C2940.7,1808.7,2940.7,1808.8,2940.7,1808.8z" />
                <path className="st3" d="M2940.7,1837.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2942.8,1832.6,2940.7,1834.7,2940.7,1837.3L2940.7,1837.3z" />
                <path className="st3" d="M2940.7,1865.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2942.8,1861.1,2940.7,1863.2,2940.7,1865.8L2940.7,1865.8z" />
                <path className="st3" d="M2940.7,1894.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2942.8,1889.6,2940.7,1891.7,2940.7,1894.3L2940.7,1894.3z" />
                <path className="st3" d="M2912.2,1780.2c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2914.3,1775.5,2912.2,1777.6,2912.2,1780.2L2912.2,1780.2z" />
                <path className="st3" d="M2912.2,1808.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2914.3,1804,2912.2,1806.1,2912.2,1808.8C2912.2,1808.7,2912.2,1808.8,2912.2,1808.8z" />
                <path className="st3" d="M2912.2,1837.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2914.3,1832.6,2912.2,1834.7,2912.2,1837.3L2912.2,1837.3z" />
                <path className="st3" d="M2912.2,1865.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2914.3,1861.1,2912.2,1863.2,2912.2,1865.8L2912.2,1865.8z" />
                <path className="st3" d="M2912.2,1894.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2914.3,1889.6,2912.2,1891.7,2912.2,1894.3L2912.2,1894.3z" />
                <path className="st3" d="M2912.2,1922.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2914.3,1918.1,2912.2,1920.2,2912.2,1922.8L2912.2,1922.8z" />
                <path className="st3" d="M2883.7,1780.2c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2885.8,1775.5,2883.7,1777.6,2883.7,1780.2L2883.7,1780.2z" />
                <path className="st3" d="M2883.7,1808.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2885.8,1804,2883.7,1806.1,2883.7,1808.8C2883.7,1808.7,2883.7,1808.8,2883.7,1808.8z" />
                <path className="st3" d="M2883.7,1837.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2885.8,1832.6,2883.7,1834.7,2883.7,1837.3L2883.7,1837.3z" />
                <path className="st3" d="M2883.7,1865.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2885.8,1861.1,2883.7,1863.2,2883.7,1865.8L2883.7,1865.8z" />
                <path className="st3" d="M2883.7,1894.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2885.8,1889.6,2883.7,1891.7,2883.7,1894.3L2883.7,1894.3z" />
                <path className="st3" d="M2883.7,1922.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2885.8,1918.1,2883.7,1920.2,2883.7,1922.8L2883.7,1922.8z" />
                <path className="st3" d="M2855.2,1780.2c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2857.3,1775.5,2855.2,1777.6,2855.2,1780.2L2855.2,1780.2z" />
                <path className="st3" d="M2855.2,1808.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2857.3,1804,2855.2,1806.1,2855.2,1808.8C2855.2,1808.7,2855.2,1808.8,2855.2,1808.8z" />
                <path className="st3" d="M2855.2,1837.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2857.3,1832.6,2855.2,1834.7,2855.2,1837.3L2855.2,1837.3z" />
                <path className="st3" d="M2855.2,1865.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2857.3,1861.1,2855.2,1863.2,2855.2,1865.8L2855.2,1865.8z" />
                <path className="st3" d="M2855.2,1894.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2857.3,1889.6,2855.2,1891.7,2855.2,1894.3L2855.2,1894.3z" />
                <path className="st3" d="M2855.2,1922.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2857.3,1918.1,2855.2,1920.2,2855.2,1922.8L2855.2,1922.8z" />
                <path className="st3" d="M2855.2,1951.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2857.3,1946.6,2855.2,1948.7,2855.2,1951.3L2855.2,1951.3z" />
                <path className="st3" d="M2826.9,1780.2c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2826.9,1777.6,2826.9,1780.2L2826.9,1780.2z" />
                <path className="st3" d="M2826.9,1808.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2829,1804,2826.9,1806.1,2826.9,1808.8C2826.9,1808.7,2826.9,1808.8,2826.9,1808.8z" />
                <path className="st3" d="M2826.9,1837.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2826.9,1834.7,2826.9,1837.3L2826.9,1837.3z" />
                <path className="st3" d="M2826.9,1865.8c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2826.9,1863.2,2826.9,1865.8L2826.9,1865.8z" />
                <path className="st3" d="M2826.9,1894.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2826.9,1891.7,2826.9,1894.3L2826.9,1894.3z" />
                <path className="st3" d="M2826.9,1922.8c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2826.9,1920.2,2826.9,1922.8L2826.9,1922.8z" />
                <path className="st3" d="M2826.9,1951.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2826.9,1948.7,2826.9,1951.3L2826.9,1951.3z" />
                <path className="st3" d="M2997.8,1693.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2999.9,1688.9,2997.8,1691,2997.8,1693.6L2997.8,1693.6z" />
                <path className="st3" d="M2997.8,1722.1c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2999.9,1717.4,2997.8,1719.5,2997.8,1722.1L2997.8,1722.1z" />
                <path className="st3" d="M2997.8,1750.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2999.9,1745.9,2997.8,1748,2997.8,1750.6L2997.8,1750.6z" />
                <path className="st3" d="M2969.3,1636.4c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2971.4,1631.7,2969.3,1633.8,2969.3,1636.4C2969.3,1636.4,2969.3,1636.4,2969.3,1636.4z" />
                <path className="st3" d="M2969.3,1665.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2971.4,1660.6,2969.3,1662.7,2969.3,1665.3C2969.3,1665.2,2969.3,1665.3,2969.3,1665.3z" />
                <path className="st3" d="M2969.3,1693.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2971.4,1688.9,2969.3,1691,2969.3,1693.6C2969.3,1693.6,2969.3,1693.6,2969.3,1693.6z" />
                <path className="st3" d="M2969.3,1722.1c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2971.4,1717.4,2969.3,1719.5,2969.3,1722.1C2969.3,1722.1,2969.3,1722.1,2969.3,1722.1z" />
                <path className="st3" d="M2969.3,1750.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c0,0,0,0,0,0
							C2971.4,1745.9,2969.3,1748,2969.3,1750.6C2969.3,1750.6,2969.3,1750.6,2969.3,1750.6z" />
                <path className="st3" d="M2940.7,1608c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2942.8,1603.4,2940.7,1605.5,2940.7,1608L2940.7,1608z" />
                <path className="st3" d="M2940.7,1636.4c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2942.8,1631.7,2940.7,1633.8,2940.7,1636.4L2940.7,1636.4z" />
                <path className="st3" d="M2940.7,1665.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2942.8,1660.6,2940.7,1662.7,2940.7,1665.3L2940.7,1665.3z" />
                <path className="st3" d="M2940.7,1693.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2942.8,1688.9,2940.7,1691,2940.7,1693.6L2940.7,1693.6z" />
                <path className="st3" d="M2940.7,1722.1c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2942.8,1717.4,2940.7,1719.5,2940.7,1722.1L2940.7,1722.1z" />
                <path className="st3" d="M2940.7,1750.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2942.8,1745.9,2940.7,1748,2940.7,1750.6L2940.7,1750.6z" />
                <path className="st3" d="M2912.2,1608c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2914.3,1603.4,2912.2,1605.5,2912.2,1608L2912.2,1608z" />
                <path className="st3" d="M2912.2,1636.4c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2914.3,1631.7,2912.2,1633.8,2912.2,1636.4L2912.2,1636.4z" />
                <path className="st3" d="M2912.2,1665.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2914.3,1660.6,2912.2,1662.7,2912.2,1665.3L2912.2,1665.3z" />
                <path className="st3" d="M2912.2,1693.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2914.3,1688.9,2912.2,1691,2912.2,1693.6L2912.2,1693.6z" />
                <path className="st3" d="M2912.2,1722.1c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2914.3,1717.4,2912.2,1719.5,2912.2,1722.1L2912.2,1722.1z" />
                <path className="st3" d="M2912.2,1750.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2914.3,1745.9,2912.2,1748,2912.2,1750.6L2912.2,1750.6z" />
                <path className="st3" d="M2883.7,1579.5c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2885.8,1574.8,2883.7,1576.9,2883.7,1579.5L2883.7,1579.5z" />
                <path className="st3" d="M2883.7,1608c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2885.8,1603.4,2883.7,1605.5,2883.7,1608L2883.7,1608z" />
                <path className="st3" d="M2883.7,1636.4c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2885.8,1631.7,2883.7,1633.8,2883.7,1636.4L2883.7,1636.4z" />
                <path className="st3" d="M2883.7,1665.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2885.8,1660.6,2883.7,1662.7,2883.7,1665.3L2883.7,1665.3z" />
                <path className="st3" d="M2883.7,1693.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2885.8,1688.9,2883.7,1691,2883.7,1693.6L2883.7,1693.6z" />
                <path className="st3" d="M2883.7,1722.1c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2885.8,1717.4,2883.7,1719.5,2883.7,1722.1L2883.7,1722.1z" />
                <path className="st3" d="M2883.7,1750.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2885.8,1745.9,2883.7,1748,2883.7,1750.6L2883.7,1750.6z" />
                <path className="st3" d="M2855.2,1579.5c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2857.3,1574.8,2855.2,1576.9,2855.2,1579.5L2855.2,1579.5z" />
                <path className="st3" d="M2855.2,1608c-0.1,2.6,2,4.8,4.6,4.8s4.8-2,4.8-4.6c0.1-2.6-2-4.8-4.6-4.8c-0.1,0-0.1,0-0.2,0
							C2857.3,1603.5,2855.3,1605.5,2855.2,1608z" />
                <path className="st3" d="M2855.2,1636.4c-0.1,2.6,1.9,4.8,4.5,4.9c2.6,0.1,4.8-1.9,4.9-4.5c0.1-2.6-1.9-4.8-4.5-4.9
							c-0.1,0-0.2,0-0.2,0C2857.3,1631.9,2855.3,1633.9,2855.2,1636.4z" />
                <path className="st3" d="M2855.2,1665.3c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2857.3,1660.6,2855.2,1662.7,2855.2,1665.3L2855.2,1665.3z" />
                <path className="st3" d="M2855.2,1693.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2857.3,1688.9,2855.2,1691,2855.2,1693.6L2855.2,1693.6z" />
                <path className="st3" d="M2855.2,1722.1c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2857.3,1717.4,2855.2,1719.5,2855.2,1722.1L2855.2,1722.1z" />
                <path className="st3" d="M2855.2,1750.6c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							C2857.3,1745.9,2855.2,1748,2855.2,1750.6L2855.2,1750.6z" />
                <path className="st3" d="M2826.9,1579.5c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2826.9,1576.9,2826.9,1579.5L2826.9,1579.5z" />
                <path className="st3" d="M2826.9,1608c-0.1,2.6,2,4.7,4.6,4.8c2.6,0.1,4.7-2,4.8-4.6c0.1-2.6-2-4.7-4.6-4.8c-0.1,0-0.2,0-0.3,0
							C2828.9,1603.6,2826.9,1605.6,2826.9,1608z" />
                <path className="st3" d="M2826.9,1636.4c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2826.9,1633.8,2826.9,1636.4L2826.9,1636.4z" />
                <path className="st3" d="M2826.9,1665.3c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2826.9,1662.7,2826.9,1665.3L2826.9,1665.3z" />
                <path className="st3" d="M2826.9,1693.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2826.9,1691,2826.9,1693.6
							L2826.9,1693.6z" />
                <path className="st3" d="M2826.9,1722.1c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7
							S2826.9,1719.5,2826.9,1722.1L2826.9,1722.1z" />
                <path className="st3" d="M2826.9,1750.6c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7S2826.9,1748,2826.9,1750.6
							L2826.9,1750.6z" />
              </g>
            </g>
          </g>
          <g className="st13">
            <g>
              <path className="st3" d="M2306.4,1948c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2309.7,1940.5,2306.4,1943.9,2306.4,1948C2306.4,1948,2306.4,1948,2306.4,1948z" />
              <path className="st3" d="M2306.4,1993.2c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2309.7,1985.7,2306.4,1989,2306.4,1993.2C2306.4,1993.2,2306.4,1993.2,2306.4,1993.2z" />
              <path className="st3" d="M2306.4,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2309.7,2030.9,2306.4,2034.2,2306.4,2038.3C2306.4,2038.3,2306.4,2038.3,2306.4,2038.3z" />
              <path className="st3" d="M2260.8,1902.8c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C2264.2,1895.4,2260.8,1898.7,2260.8,1902.8z" />
              <path className="st3" d="M2260.8,1948c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2264.2,1940.6,2260.8,1943.9,2260.8,1948z" />
              <path className="st3" d="M2260.8,1993.2c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2264.2,1985.7,2260.8,1989.1,2260.8,1993.2z" />
              <path className="st3" d="M2260.8,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5s-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2264.2,2030.9,2260.8,2034.2,2260.8,2038.3z" />
              <path className="st3" d="M2215.2,1857.7c-0.1,4.2,3.3,7.6,7.4,7.7c4.2,0.1,7.6-3.3,7.7-7.4c0.1-4.2-3.3-7.6-7.4-7.7
						c0,0-0.1,0-0.1,0C2218.6,1850.2,2215.3,1853.5,2215.2,1857.7z" />
              <path className="st3" d="M2215.2,1902.8c-0.1,4.2,3.3,7.6,7.4,7.7c4.2,0.1,7.6-3.3,7.7-7.4c0.1-4.2-3.3-7.6-7.4-7.7
						c0,0-0.1,0-0.1,0C2218.6,1895.4,2215.3,1898.7,2215.2,1902.8z" />
              <path className="st3" d="M2215.2,1948c0,4.2,3.3,7.6,7.5,7.6c4.2,0,7.6-3.3,7.6-7.5c0-4.2-3.3-7.6-7.5-7.6c0,0-0.1,0-0.1,0
						C2218.6,1940.5,2215.3,1943.9,2215.2,1948C2215.2,1948,2215.2,1948,2215.2,1948z" />
              <path className="st3" d="M2215.2,1993.2c0,4.2,3.3,7.6,7.5,7.6c4.2,0,7.6-3.3,7.6-7.5c0-4.2-3.3-7.6-7.5-7.6c0,0-0.1,0-0.1,0
						C2218.6,1985.7,2215.3,1989,2215.2,1993.2C2215.2,1993.2,2215.2,1993.2,2215.2,1993.2z" />
              <path className="st3" d="M2215.2,2038.3c0,4.2,3.3,7.6,7.5,7.6c4.2,0,7.6-3.3,7.6-7.5c0-4.2-3.3-7.6-7.5-7.6c0,0-0.1,0-0.1,0
						C2218.6,2030.9,2215.3,2034.2,2215.2,2038.3C2215.2,2038.3,2215.2,2038.3,2215.2,2038.3z" />
              <path className="st3" d="M2169.7,1812.5c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C2173.1,1805.1,2169.7,1808.4,2169.7,1812.5C2169.7,1812.5,2169.7,1812.5,2169.7,1812.5z" />
              <path className="st3" d="M2169.7,1857.7c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C2173.1,1850.2,2169.7,1853.6,2169.7,1857.7C2169.7,1857.7,2169.7,1857.7,2169.7,1857.7z" />
              <path className="st3" d="M2169.7,1902.8c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C2173.1,1895.4,2169.7,1898.7,2169.7,1902.8C2169.7,1902.8,2169.7,1902.8,2169.7,1902.8z" />
              <path className="st3" d="M2169.7,1948c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2173.1,1940.5,2169.7,1943.9,2169.7,1948C2169.7,1948,2169.7,1948,2169.7,1948z" />
              <path className="st3" d="M2169.7,1993.2c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2173.1,1985.7,2169.7,1989,2169.7,1993.2C2169.7,1993.2,2169.7,1993.2,2169.7,1993.2z" />
              <path className="st3" d="M2169.7,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2173.1,2030.9,2169.7,2034.2,2169.7,2038.3C2169.7,2038.3,2169.7,2038.3,2169.7,2038.3z" />
              <path className="st3" d="M2124.1,1812.5c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C2127.5,1805.1,2124.2,1808.4,2124.1,1812.5z" />
              <path className="st3" d="M2124.1,1857.7c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C2127.5,1850.3,2124.2,1853.6,2124.1,1857.7z" />
              <path className="st3" d="M2124.1,1902.8c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C2127.5,1895.4,2124.2,1898.7,2124.1,1902.8z" />
              <path className="st3" d="M2124.1,1948c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2127.5,1940.6,2124.2,1943.9,2124.1,1948z" />
              <path className="st3" d="M2124.1,1993.2c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2127.5,1985.7,2124.2,1989.1,2124.1,1993.2z" />
              <path className="st3" d="M2124.1,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2127.5,2030.9,2124.2,2034.2,2124.1,2038.3z" />
              <path className="st3" d="M2078.6,1812.5c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4s-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C2082,1805.1,2078.6,1808.4,2078.6,1812.5C2078.6,1812.5,2078.6,1812.5,2078.6,1812.5z" />
              <path className="st3" d="M2078.6,1857.7c0,4.1,3.3,7.5,7.4,7.6s7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C2082,1850.2,2078.6,1853.6,2078.6,1857.7C2078.6,1857.7,2078.6,1857.7,2078.6,1857.7z" />
              <path className="st3" d="M2078.6,1902.8c0,4.1,3.3,7.5,7.4,7.6s7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C2082,1895.4,2078.6,1898.7,2078.6,1902.8C2078.6,1902.8,2078.6,1902.8,2078.6,1902.8z" />
              <path className="st3" d="M2078.6,1948c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2082,1940.5,2078.6,1943.9,2078.6,1948C2078.6,1948,2078.6,1948,2078.6,1948z" />
              <path className="st3" d="M2078.6,1993.2c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2082,1985.7,2078.6,1989,2078.6,1993.2C2078.6,1993.2,2078.6,1993.2,2078.6,1993.2z" />
              <path className="st3" d="M2078.6,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2082,2030.9,2078.6,2034.2,2078.6,2038.3C2078.6,2038.3,2078.6,2038.3,2078.6,2038.3z" />
              <path className="st3" d="M2033,1767.4c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						c-4.1,0-7.5,3.2-7.5,7.3C2033,1767.3,2033,1767.3,2033,1767.4z" />
              <path className="st3" d="M2033,1812.5c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C2036.4,1805.1,2033.1,1808.4,2033,1812.5C2033,1812.5,2033,1812.5,2033,1812.5z" />
              <path className="st3" d="M2033,1857.7c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C2036.4,1850.2,2033.1,1853.6,2033,1857.7C2033,1857.7,2033,1857.7,2033,1857.7z" />
              <path className="st3" d="M2033,1902.8c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C2036.4,1895.4,2033.1,1898.7,2033,1902.8C2033,1902.8,2033,1902.8,2033,1902.8z" />
              <path className="st3" d="M2033,1948c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2036.4,1940.5,2033.1,1943.9,2033,1948C2033,1948,2033,1948,2033,1948z" />
              <path className="st3" d="M2033,1993.2c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2036.4,1985.7,2033.1,1989,2033,1993.2C2033,1993.2,2033,1993.2,2033,1993.2z" />
              <path className="st3" d="M2033,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C2036.4,2030.9,2033.1,2034.2,2033,2038.3C2033,2038.3,2033,2038.3,2033,2038.3z" />
              <path className="st3" d="M1987.5,1767.4c0,4.2,3.3,7.6,7.5,7.6c4.2,0,7.6-3.3,7.6-7.5c0-4.2-3.3-7.6-7.5-7.6c0,0-0.1,0-0.1,0
						C1990.9,1759.9,1987.5,1763.2,1987.5,1767.4C1987.5,1767.3,1987.5,1767.4,1987.5,1767.4z" />
              <path className="st3" d="M1987.5,1812.5c-0.1,4.2,3.3,7.6,7.4,7.7s7.6-3.3,7.7-7.4c0.1-4.2-3.3-7.6-7.4-7.7c-0.1,0-0.1,0-0.2,0
						C1990.9,1805.1,1987.5,1808.4,1987.5,1812.5z" />
              <path className="st3" d="M1987.5,1857.7c-0.1,4.2,3.3,7.6,7.4,7.7c4.2,0.1,7.6-3.3,7.7-7.4s-3.3-7.6-7.4-7.7c-0.1,0-0.1,0-0.2,0
						C1990.9,1850.3,1987.5,1853.6,1987.5,1857.7z" />
              <path className="st3" d="M1987.5,1902.8c-0.1,4.2,3.3,7.6,7.4,7.7c4.2,0.1,7.6-3.3,7.7-7.4c0.1-4.2-3.3-7.6-7.4-7.7
						c-0.1,0-0.1,0-0.2,0C1990.9,1895.4,1987.5,1898.7,1987.5,1902.8z" />
              <path className="st3" d="M1987.5,1948c0,4.2,3.3,7.6,7.5,7.6c4.2,0,7.6-3.3,7.6-7.5c0-4.2-3.3-7.6-7.5-7.6c0,0-0.1,0-0.1,0
						C1990.9,1940.6,1987.5,1943.9,1987.5,1948z" />
              <path className="st3" d="M1987.5,1993.2c0,4.2,3.3,7.6,7.5,7.6c4.2,0,7.6-3.3,7.6-7.5c0-4.2-3.3-7.6-7.5-7.6c0,0-0.1,0-0.1,0
						C1990.9,1985.7,1987.5,1989.1,1987.5,1993.2z" />
              <path className="st3" d="M1987.5,2038.3c0,4.2,3.3,7.6,7.5,7.6c4.2,0,7.6-3.3,7.6-7.5c0-4.2-3.3-7.6-7.5-7.6c0,0-0.1,0-0.1,0
						C1990.9,2030.9,1987.5,2034.2,1987.5,2038.3z" />
              <path className="st3" d="M1942,1767.4c-0.1,4.1,3.2,7.6,7.3,7.7c4.1,0.1,7.6-3.2,7.7-7.3c0.1-4.1-3.2-7.6-7.3-7.7
						c-0.1,0-0.2,0-0.3,0C1945.3,1760.1,1942,1763.3,1942,1767.4z" />
              <path className="st3" d="M1942,1812.5c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4s-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1945.3,1805.1,1942,1808.4,1942,1812.5C1942,1812.5,1942,1812.5,1942,1812.5z" />
              <path className="st3" d="M1942,1857.7c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1945.3,1850.2,1942,1853.6,1942,1857.7C1942,1857.7,1942,1857.7,1942,1857.7z" />
              <path className="st3" d="M1942,1902.8c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1945.3,1895.4,1942,1898.7,1942,1902.8C1942,1902.8,1942,1902.8,1942,1902.8z" />
              <path className="st3" d="M1942,1948c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1945.3,1940.5,1942,1943.9,1942,1948C1942,1948,1942,1948,1942,1948z" />
              <path className="st3" d="M1942,1993.2c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1945.3,1985.7,1942,1989,1942,1993.2C1942,1993.2,1942,1993.2,1942,1993.2z" />
              <path className="st3" d="M1942,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5s-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1945.3,2030.9,1942,2034.2,1942,2038.3C1942,2038.3,1942,2038.3,1942,2038.3z" />
              <path className="st3" d="M1896.4,1812.5c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4s-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1899.8,1805.1,1896.4,1808.4,1896.4,1812.5z" />
              <path className="st3" d="M1896.4,1857.7c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1899.8,1850.3,1896.4,1853.6,1896.4,1857.7z" />
              <path className="st3" d="M1896.4,1902.8c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1899.8,1895.4,1896.4,1898.7,1896.4,1902.8z" />
              <path className="st3" d="M1896.4,1948c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1899.8,1940.6,1896.4,1943.9,1896.4,1948z" />
              <path className="st3" d="M1896.4,1993.2c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1899.8,1985.7,1896.4,1989.1,1896.4,1993.2z" />
              <path className="st3" d="M1896.4,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5s-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1899.8,2030.9,1896.4,2034.2,1896.4,2038.3z" />
              <path className="st3" d="M1850.9,1812.5c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1854.2,1805.1,1850.9,1808.4,1850.9,1812.5C1850.9,1812.5,1850.9,1812.5,1850.9,1812.5z" />
              <path className="st3" d="M1850.9,1857.7c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1854.2,1850.2,1850.9,1853.6,1850.9,1857.7C1850.9,1857.7,1850.9,1857.7,1850.9,1857.7z" />
              <path className="st3" d="M1850.9,1902.8c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1854.2,1895.4,1850.9,1898.7,1850.9,1902.8C1850.9,1902.8,1850.9,1902.8,1850.9,1902.8z" />
              <path className="st3" d="M1850.9,1948c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1854.2,1940.5,1850.9,1943.9,1850.9,1948C1850.9,1948,1850.9,1948,1850.9,1948z" />
              <path className="st3" d="M1850.9,1993.2c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1854.2,1985.7,1850.9,1989,1850.9,1993.2C1850.9,1993.2,1850.9,1993.2,1850.9,1993.2z" />
              <path className="st3" d="M1850.9,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1854.2,2030.9,1850.9,2034.2,1850.9,2038.3C1850.9,2038.3,1850.9,2038.3,1850.9,2038.3z" />
              <path className="st3" d="M1805.1,1812.5c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4s-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1808.5,1805.1,1805.2,1808.4,1805.1,1812.5C1805.1,1812.5,1805.1,1812.5,1805.1,1812.5z" />
              <path className="st3" d="M1805.1,1857.7c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1808.5,1850.2,1805.2,1853.6,1805.1,1857.7C1805.1,1857.7,1805.1,1857.7,1805.1,1857.7z" />
              <path className="st3" d="M1805.1,1902.8c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1808.5,1895.4,1805.2,1898.7,1805.1,1902.8C1805.1,1902.8,1805.1,1902.8,1805.1,1902.8z" />
              <path className="st3" d="M1805.1,1948c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1808.5,1940.5,1805.2,1943.9,1805.1,1948C1805.1,1948,1805.1,1948,1805.1,1948z" />
              <path className="st3" d="M1805.1,1993.2c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1808.5,1985.7,1805.2,1989,1805.1,1993.2C1805.1,1993.2,1805.1,1993.2,1805.1,1993.2z" />
              <path className="st3" d="M1805.1,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1808.5,2030.9,1805.2,2034.2,1805.1,2038.3C1805.1,2038.3,1805.1,2038.3,1805.1,2038.3z" />
              <path className="st3" d="M1759.8,1857.7c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1763.2,1850.3,1759.9,1853.6,1759.8,1857.7z" />
              <path className="st3" d="M1759.8,1902.8c0,4.1,3.3,7.5,7.4,7.6c4.1,0,7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1763.2,1895.4,1759.9,1898.7,1759.8,1902.8z" />
              <path className="st3" d="M1759.8,1948c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1763.2,1940.6,1759.8,1943.9,1759.8,1948z" />
              <path className="st3" d="M1759.8,1993.2c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1763.2,1985.7,1759.8,1989.1,1759.8,1993.2z" />
              <path className="st3" d="M1759.8,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1763.2,2030.9,1759.8,2034.2,1759.8,2038.3z" />
              <path className="st3" d="M1714.2,1902.8c0,4.1,3.3,7.5,7.4,7.6s7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
						C1717.6,1895.4,1714.2,1898.7,1714.2,1902.8C1714.2,1902.8,1714.2,1902.8,1714.2,1902.8z" />
              <path className="st3" d="M1714.2,1948c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1717.6,1940.5,1714.2,1943.9,1714.2,1948C1714.2,1948,1714.2,1948,1714.2,1948z" />
              <path className="st3" d="M1714.2,1993.2c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1717.6,1985.7,1714.2,1989,1714.2,1993.2C1714.2,1993.2,1714.2,1993.2,1714.2,1993.2z" />
              <path className="st3" d="M1714.2,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1717.6,2030.9,1714.2,2034.2,1714.2,2038.3C1714.2,2038.3,1714.2,2038.3,1714.2,2038.3z" />
              <path className="st3" d="M1668.6,1948c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1672,1940.6,1668.7,1943.9,1668.6,1948z" />
              <path className="st3" d="M1668.6,1993.2c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1672,1985.7,1668.7,1989.1,1668.6,1993.2z" />
              <path className="st3" d="M1668.6,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
						C1672,2030.9,1668.7,2034.2,1668.6,2038.3z" />
              <g>
                <defs>
                  <path id="SVGID_15_" d="M2364.7,2053.9c-37.4-172.4-192-301.7-377.1-301.7s-339.7,129.2-377.1,301.7H2364.7z" />
                </defs>
                <clipPath id="SVGID_16_">
                  <use xlinkHref="#SVGID_15_" style={{overflow: 'visible'}} />
                </clipPath>
                <g className="st14">
                  <g>
                    <path className="st3" d="M2351.9,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5s-3.3-7.5-7.5-7.5c0,0,0,0,0,0
									C2355.3,2030.9,2351.9,2034.2,2351.9,2038.3z" />
                  </g>
                  <g>
                    <path className="st3" d="M2078.6,1767.4c-0.1,4.1,3.2,7.6,7.3,7.7s7.6-3.2,7.7-7.3c0.1-4.1-3.2-7.6-7.3-7.7c-0.1,0-0.1,0-0.2,0
									C2082,1760,2078.7,1763.3,2078.6,1767.4C2078.6,1767.4,2078.6,1767.4,2078.6,1767.4z" />
                  </g>
                  <g>
                    <path className="st3" d="M1896.4,1767.4c-0.1,4.1,3.2,7.6,7.3,7.7c4.1,0.1,7.6-3.2,7.7-7.3c0.1-4.1-3.2-7.6-7.3-7.7c0,0,0,0,0,0
									C1899.9,1759.9,1896.5,1763.2,1896.4,1767.4C1896.4,1767.3,1896.4,1767.4,1896.4,1767.4z" />
                  </g>
                  <g>
                    <path className="st3" d="M1850.9,1767.4c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
									c-4.1,0-7.5,3.2-7.5,7.3C1850.9,1767.3,1850.9,1767.3,1850.9,1767.4z" />
                  </g>
                  <g>
                    <path className="st3" d="M1714.2,1857.7c0,4.1,3.3,7.5,7.4,7.6s7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
									C1717.6,1850.2,1714.2,1853.6,1714.2,1857.7C1714.2,1857.7,1714.2,1857.7,1714.2,1857.7z" />
                  </g>
                  <g>
                    <path className="st3" d="M1668.6,1902.8c0,4.1,3.3,7.5,7.4,7.6s7.5-3.3,7.6-7.4c0-4.1-3.3-7.5-7.4-7.6c0,0-0.1,0-0.1,0
									C1672,1895.4,1668.7,1898.7,1668.6,1902.8z" />
                  </g>
                  <g>
                    <path className="st3" d="M1623.1,1993.2c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
									C1626.5,1985.7,1623.1,1989,1623.1,1993.2C1623.1,1993.2,1623.1,1993.2,1623.1,1993.2z" />
                  </g>
                  <g>
                    <path className="st3" d="M1623.1,2038.3c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5c0,0,0,0,0,0
									C1626.5,2030.9,1623.1,2034.2,1623.1,2038.3C1623.1,2038.3,1623.1,2038.3,1623.1,2038.3z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <polygon id="poly1" className="st15" points="1920.5,638.8 1887.7,695.7 1854.8,638.8 			" />
          <polygon id="poly2" className="st15" points="819.2,854.6 769.7,940.2 720.2,854.6 			" />
          <polygon id="poly3" className="st15" points="2203.1,1624.8 2170.2,1681.8 2137.3,1624.8 			" />
          <polygon id="poly4" className="st15" points="1920.5,515.5 1887.7,572.5 1854.8,515.5 			" />
          <polygon id="poly5" className="st15" points="1039.2,440 1006.3,496.9 973.4,440 			" />
          <polygon id="poly6" className="st15" points="1453.8,1494 1420.9,1551 1388.1,1494 			" />
          <polygon id="poly7" className="st15" points="2709,1295.5 2676.1,1352.5 2643.2,1295.5 			" />
          <polygon id="poly8" className="st15" points="1267.8,1352.5 1234.9,1409.5 1202,1352.5 			" />
          <polygon id="poly9" className="st15" points="395.6,1465.5 362.7,1522.5 329.9,1465.5 			" />
          <path id="tri1" className="st3" d="M2437,376.9h-106l53-91.8L2437,376.9z M2338.1,372.8h91.8l-45.9-79.4L2338.1,372.8z" />
          <path id="tri2" className="st3" d="M822.7,1872.5H716.6l53.1-91.8L822.7,1872.5z M723.8,1868.3h91.8l-45.9-79.5L723.8,1868.3z" />
          <path id="tri3" className="st3" d="M178.4,1518.1H72.3l53.1-91.8L178.4,1518.1z M79.5,1514h91.8l-45.9-79.4L79.5,1514z" />
          <path id="tri4" className="st3" d="M2955,1283.4h-106l53-91.9L2955,1283.4z M2856.1,1279.2h91.8l-45.9-79.5L2856.1,1279.2z" />
        </g>
      </g>
    </g>
  </svg>
</div>

  )
}

export default Backgroundanimation
