import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import axios from 'axios';
import style from './TermsCondition.module.css';


const TermsConditions = () => {
    const [termsData, setTermsData] = useState({});

    useEffect(() => {
      const fetchTermsData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_WEBPORTAL_URL}/api/termscondition`);
          setTermsData(response.data.ResponseData[0]);
        } catch (error) {
          console.error('Error fetching data from API:', error);
        }
      };
  
      fetchTermsData();
    }, []);

    return (
        <> 
         <div className='mt-50'>
            <div className={style.popularFlex} >
                <h2 className={style.headPopular}>Terms & Conditions</h2>
            </div>
        </div>
        <Container>
        <div className="App mt-5 mb-5">
          <div className="separator">
            <div
              className="text-left "
              style={{ fontSize: "16px", lineHeight: "1.6", color: "black", textAlign: "justify"  }}
              dangerouslySetInnerHTML={{ __html: termsData.description  }}
            />
          </div>
        </div>
      </Container>
   
  
        </>
    )
}

export default TermsConditions