import React from 'react'
import { useNavigate } from 'react-router-dom'
const Footer = () => {
const navigate=useNavigate();
    return (
        <>
            <section>
                <div className='mt-50 Footer'>
                    <div className='row footer' style={{}}>
                        <div className='col-12 col-md-6 col-xs-12 col-lg-3 col-xl-4 d-flex flex-column gap-3'>
                            <h4>NIRUKA TECHNOLOGIES LLP</h4>
                            <span style={{ color: '#427ed1', fontWeight: 'bold', fontSize: '21px' }}>Analysistabs Premium Products & Service</span>
                            <p className='footerpara'>ANALYSISTABS.ORG is Secured Market Place Created to Sell Premium Products and Services By <b> NIRUKA TECHNOLOGIES LLP.</b> We Offer Premium Products including Project Management Templates, Business Presentations, Dashboards and VBA Tool & Courses.</p>
                        </div>
                        <div className='col-12  col-md-1 col-xs-12 col-lg-1 col-xl-1'></div>
                        <div className='col-12  col-md-6 col-xs-12 col-lg-3 col-xl-3 d-flex flex-column gap-2'>
                            <h4 >SUPPORT</h4>
                            <span>Need Assistance?</span>
                            <span>Ask Your Queries:<a style={{ color: "rgb(66, 126, 209)" }}> Support</a></span>
                            <span>Please refer:<a style={{ color: "rgb(66, 126, 209)" }}> FAQs</a></span>
                            <span>Demo Videos:<a style={{ color: "rgb(66, 126, 209)" }}> Preview Videos</a></span>
                            <span className='footerpara'>Protected By DM©A</span>
                        </div>
                        <div className='col-12  col-md-5 col-xs-12 col-lg-3 col-xl-4 d-flex flex-column gap-2 '>
                            <h4 className='mb-3'>CONTACT US</h4>
                            <span className='footermail' style={{ color: 'rgb(66, 126, 209)', fontWeight: 'bold', fontSize: '21px' }}>Sales/Support:<a href="" style={{ color: "rgb(66, 126, 209)" }}>info@analysistabs.org</a></span>
                            <h4 className='mb-3 mt-3'>TERMS</h4>
                            <li className='list-unstyled'><a href="" className='text-white' onClick={() => navigate('/termsconditions')} >Terms of Use</a></li>
                            <li className='list-unstyled'><a href="" className='text-white' onClick={() => navigate('/privacy')} >Privacy Policy</a></li>
                            <li className='list-unstyled'><a href="" className='text-white' onClick={() => navigate('/licenses')}>Our Licenses</a></li>
                        </div>
                    </div>
                </div>
                <div className='footercopyright' style={{ backgroundColor: '#1d1f22', color: 'white', textAlign: 'center', padding: '20px 0' }}>
                    <p>© Copyright 2012 - 2024   |   ANALYSISTABS.ORG (Premium Products & Services) By ANALYSISTABS.COM   |   All Rights Reserved.</p>
                </div>
            </section>
        </>
    )
}

export default Footer
