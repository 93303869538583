import React from 'react'
import HelpSection from './HelpSection/HelpSection'
import VideoTemplates from './VideoTemplates/VideoTemplates'

const PreviewVideos = () => {
  return (
   <>
   {/* <HelpSection/> */}
   <VideoTemplates/>
   </>
  )
}

export default PreviewVideos
