import React from 'react'

const WallPaperPrivacyPolicy = () => {
  return (
    <>
      <section style={{}}>
        <div className="container p-xxl-5 mt-5  rounded shadow para" style={{ textAlign: 'justify', color: '#274868', backgroundColor: '#f7ebee' }}>
          <div className="row">
            <div className="col-12">
              <h1 className="text-center text-decoration-underline mb-5 fw-bold" style={{ color: '#8f645c' }}>Privacy Policy</h1>
              <p className='mb-2'>Last updated:<i> 10-Jan-2024</i> </p>
              <p>Your privacy is important to us and we are committed to protecting your privacy.when you use our services
                mobile applications, we may collect and use your related information. If the related information was not
                provided, otherwise you may not register as our user to use some of our services or we may not able to
                operate effectively and provide you with the best experiences from our services. So we’ve developed a
                Privacy Policy that covers aspects of how we collect, use, disclose, transfer, and store your information.
                Please take a moment to familiarize yourself with our privacy practices and let us know if you have any
                questions.</p>
              <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of
                Personal Information if anyone decided to use our Service.</p>
              <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this
                policy. The Personal Information that we collect is used for providing and improving the Service. We will
                not use or share your information with anyone except as described in this Privacy Policy.</p>
              <h5 className="text-decoration-underline fw-bold mb-2 mt-2" style={{ color: '#8f645c' }}>Information Collection and Use :-</h5>
              <h6>User Registration</h6>
              <p>To access certain features of our wallpaper app, you are required to register by providing the following
                information:</p>
              <ul className="">
                <div className="d-flex align-items-center">
                  <li ><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-1-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312z" />
                  </svg> User name</li>
                </div>
                <li><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-2-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z" />
                </svg> Phone number</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-3-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z" />
                </svg> Email address</li>
              </ul>
              <h5 className="text-decoration-underline fw-bold mb-2 mt-2" style={{ color: '#8f645c' }}>How We Use Information :-</h5>
              <p>We collect the above-mentioned information solely for the purpose of enhancing your experience with our app.
                The information is used for the following:</p>
              <ul>
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-bookmarks-fill" viewBox="0 0 16 16">
                    <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5z" />
                    <path d="M4.268 1A2 2 0 0 1 6 0h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L13 13.768V2a1 1 0 0 0-1-1z" />
                  </svg> To personalize your experience</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-bookmarks-fill" viewBox="0 0 16 16">
                  <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5z" />
                  <path d="M4.268 1A2 2 0 0 1 6 0h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L13 13.768V2a1 1 0 0 0-1-1z" />
                </svg> To Allowing you to mark favorite wallpapers </li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-bookmarks-fill" viewBox="0 0 16 16">
                  <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5z" />
                  <path d="M4.268 1A2 2 0 0 1 6 0h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L13 13.768V2a1 1 0 0 0-1-1z" />
                </svg> To Enabling wallpaper downloads (stored within the application APK, not on your device)</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-bookmarks-fill" viewBox="0 0 16 16">
                  <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5z" />
                  <path d="M4.268 1A2 2 0 0 1 6 0h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L13 13.768V2a1 1 0 0 0-1-1z" />
                </svg> To Facilitating wallpaper sharing</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-bookmarks-fill" viewBox="0 0 16 16">
                  <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5z" />
                  <path d="M4.268 1A2 2 0 0 1 6 0h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L13 13.768V2a1 1 0 0 0-1-1z" />
                </svg> To Setting wallpapers on your device</li>
              </ul>
              <h5 className="text-decoration-underline fw-bold mb-2 mt-2" style={{ color: '#8f645c' }}>How We Share Your Information</h5>
              <p> We prioritize the security and confidentiality of your information. Rest assured that:</p>
              <ul>
                <li> <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-bullseye" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8" />
                  <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                </svg> We do not share your personal information with third parties for any purpose</li>
                <li> <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-bullseye" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8" />
                  <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                </svg> Your data is not collected in the background when you are not actively using the application</li>
              </ul>
              <h5 className="text-decoration-underline fw-bold mb-2 mt-2" style={{ color: '#8f645c' }}>Your Rights and Choice</h5>
              <p> As a user, you have the right to:</p>
              <ul>
                <li><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-1-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312z" />
                </svg> Opt-out of receiving certain information</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-2-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z" />
                </svg> Request the deletion of your entire data stored within the app.</li>
              </ul>
              <h5 className="text-decoration-underline fw-bold mb-2 mt-2" style={{ color: '#8f645c' }}>Data Security</h5>
              <p> We take the security of your information seriously. The following measures are implemented to protect your
                data:</p>
              <ul>
                <li><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-bookmarks-fill" viewBox="0 0 16 16">
                  <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5z" />
                  <path d="M4.268 1A2 2 0 0 1 6 0h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L13 13.768V2a1 1 0 0 0-1-1z" />
                </svg> connections are used to handle and transmit user information.</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-bookmarks-fill" viewBox="0 0 16 16">
                  <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5z" />
                  <path d="M4.268 1A2 2 0 0 1 6 0h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L13 13.768V2a1 1 0 0 0-1-1z" />
                </svg> Wallpaper downloads are stored within the application APK, ensuring data integrity.</li>
              </ul>
              <h5 className="text-decoration-underline fw-bold mb-2 mt-2" style={{ color: '#8f645c' }}>Third-Party Links-</h5>
              <p>Our app may contain links to third-party websites or services. Please be aware that we are not responsible
                for the privacy practices of such external entities. We encourage you to review the privacy policies of
                those third parties.</p>
              <h5 className="text-decoration-underline fw-bold mb-2 mt-2" style={{ color: '#8f645c' }}>Children's Privacy</h5>
              <p>Our app is not intended for children under the age of 13. We do not knowingly collect personal information
                from children under 13. If you are a parent or guardian and believe that your child has provided us with
                personal information, please contact us, and we will take steps to delete the information.</p>
              <h5 className="text-decoration-underline fw-bold mb-2 mt-2" style={{ color: '#8f645c' }}>Changes to This Privacy Policy :-</h5>
              <p>We reserve the right to update our privacy policy at any time. We will notify you of any changes by posting
                the new privacy policy on this page. It is advised to review this privacy policy periodically for any
                changes.</p>
              <p>If you have any questions or concerns regarding our privacy policy, please contact us at <a href="mailto:wallsengineteam.2402@gmail.com" style={{ color: '#8f645c', fontWeight: 'bold' }}>wallsengineteam.2402@gmail.com</a>.</p>
              <p><i>Thank you</i> for using our wallpaper app.</p>
            </div>
          </div>
        </div>

      </section>
    </>
  )
}

export default WallPaperPrivacyPolicy
