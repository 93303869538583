import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import logo from '../../../Images/logo.png';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faShoppingCart, faSignIn } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

const NavbarComponent = () => {
  const navigate = useNavigate();
  const [menuData, setMenuData] = useState([]);
  const [cartData, setCartData] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const userId = localStorage.getItem('userId');
  const isLoggedIn = localStorage.getItem('userId') !== null;
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(true);


  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_WEBPORTAL_URL}/api/home`);
        const data = await response.json();

        if (data.ResponseCode === 1) {
          setMenuData(data.ResponseData.menulist);
        } else {
          console.error('Failed to fetch menu data');
        }
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };

    fetchMenuData();
    fetchCartData();
  }, []);

  const fetchCartData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_WEBPORTAL_URL}/api/cart-list/${userId}`);
      const data = response.data;

      if (data.ResponseCode === 1) {
        setCartData(data.ResponseData.length); 
      } else {
        console.error('Failed to fetch cart data');
      }
    } catch (error) {
      console.error('Error fetching cart data:', error);
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = () => {
    setIsOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('phoneNumber');
    setCartData([]);
    navigate('/');
  };

  const handleSearchInputChange = async (query) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_WEBPORTAL_URL}/api/search?search=${query}`);

      if (response.data.ResponseCode === 1) {
        setSearchResults(response.data.ResponseData || []);
      } else {
        console.error('Error fetching search results:', response.data.ResponseText);
        setSearchResults([]);
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
      setSearchResults([]);
    }
  };

  const handleChange = async (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    if (value.trim() !== '') {
      await handleSearchInputChange(value);
      setShowSearchResults(true);
    } else {
      setSearchResults([]);
      setShowSearchResults(false);
    }
  };

  const handleSearchResultClick = (id) => {
    setShowSearchResults(false);
    navigate(`/productdetails/${id}`);
  };
  const [searchPerformed, setSearchPerformed] = useState(false);

  return (
    <>
      <section>
        <div>
          <nav className="navbar">
            <Container fluid className='px-xxl-5'>
              <div className="navbar-container">
                <div></div>
                <input type="checkbox" name="" id="" />
                <div className="hamburger-lines" onClick={handleToggle}>
                  <span className="line line1" />
                  <span className="line line2" />
                  <span className="line line3" />
                </div>
                <div>
                  <img src={logo} className='logo pointer' alt="" onClick={() => navigate('/')} />
                </div>
                <ul className={`menu-items ${isOpen ? 'open' : ''}`}>
                  {/* <div className="search-container d-block d-lg-none d-xxl-none">
                    <input
                      type="text"
                      placeholder="Search"
                      className=' searchInput border border-0 rounded form-control'
                      value={searchQuery}
                      onChange={handleChange}
                    />
                   {showSearchResults && (
                      <ul className="search-results position-absolute w-100 py-3 list-unstyled mt-1" style={{}}>
                        {searchResults.length > 0 ? (
                          searchResults.map((result, index) => (
                            <li key={result.id} onClick={() => handleSearchResultClick(result.id)} style={{ cursor: 'pointer', backgroundColor: 'white', zIndex: 1 }} className='pointer p-1'>
                              {index + 1}. {result.name}
                            </li>
                          ))
                        ) : (
                          searchPerformed && <li style={{ color: 'gray', padding: '5px', listStyleType: 'none' }}>No match found</li>
                        )}
                      </ul>
                    )}
                  </div> */}
                  {menuData.map((menuItem) => (
                    <li key={menuItem.id}><a className='pointer' onClick={() => { navigate(`/previewvideos/${menuItem.id}`); handleMenuItemClick(); }}>{menuItem.name} </a></li>
                  ))}
                  <li><a className='pointer' onClick={() => { navigate('/addtocart'); handleMenuItemClick(); }}>  Cart</a>
                  <span> {cartData}</span> 
                  </li>
                  <li>
                  {isLoggedIn ? (
                    <Dropdown >
                      <Dropdown.Toggle variant='none'  id="navbarDropdown" style={{ cursor: 'pointer' }}>
                    <a>Welcome</a>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item variant='none' onClick={() => navigate('/profile')}>Profile</Dropdown.Item>
                        <Dropdown.Item variant='none' onClick={() => navigate('/success')}>Purchase History</Dropdown.Item>
                        <Dropdown.Item variant='none' onClick={handleLogout}>Logout</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <li><a className=' pointer' onClick={() => { navigate('/login'); handleMenuItemClick(); }}>Login</a></li>
                  )}
                  </li>
                </ul>
                <div className="search-container d-none d-lg-none d-xxl-block">
                  <input
                    type="text"
                    placeholder="Search"
                    className='p-2 searchInput border rounded form-control'
                    value={searchQuery}
                    onChange={handleChange}
                  />
                  <div>
                    {showSearchResults && (
                      <ul className="search-results position-absolute w-100 py-3 list-unstyled mt-1" style={{}}>
                        {searchResults.length > 0 ? (
                          searchResults.map((result, index) => (
                            <li key={result.id} onClick={() => handleSearchResultClick(result.id)} style={{ cursor: 'pointer', backgroundColor: 'white', zIndex: 1 }} className='pointer p-1'>
                              {index + 1}. {result.name}
                            </li>
                          ))
                        ) : (
                          searchPerformed && <li style={{ color: 'gray', padding: '5px', listStyleType: 'none' }}>No match found</li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </Container>
          </nav>
        </div>
      </section>
    </>
  );
};

export default NavbarComponent;
