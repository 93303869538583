import logo from './logo.svg';
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './Componenets/Pages/Home/Home';
import NAvbar from './Componenets/Layout/Navbar/Navbar';
import PreviewVideos from './Componenets/Pages/PreviewVideos/PreviewVideos';
import Footer from './Componenets/Layout/Footer/Footer';
import ProductDetails from './Componenets/Pages/ProductDetails/ProductDetails';
import AddToCart from './Componenets/Pages/AddToCart/AddToCart';
import Login from './Componenets/Pages/Login/Login';
import CheckOut from './Componenets/Pages/AddToCart/CheckOut/CheckOut';
import Profile from './Componenets/Pages/Profile/Profile';
import Invoice from './Componenets/Pages/Invoice/Invoice';
import WallPaperPrivacyPolicy from './Componenets/Pages/WallPaperPrivacyPolicy/WallPaperPrivacyPolicy';
import 'react-toastify/dist/ReactToastify.css';
import TermsConditions from './Componenets/Pages/TermsConditions/TermsConditions';
import PrivacyPolicy from './Componenets/Pages/PrivacyPolicy/PrivacyPolicy';
import Licenses from './Componenets/Pages/Licenses/Licenses';
import SuccessOrder from './Componenets/Pages/SuccessOrder/SuccessOrder';


function App() {
  const navigate = useNavigate();

  // Conditionally render Navbar based on the route
  const renderNavbar = () => {
    const currentPath = window.location.pathname;
    if (currentPath === '/wallpaperstudioprivacypolicy') {
      return null; // Do not render Navbar for the privacy policy route
    }
    return <NAvbar  />;
  };
  const renderFooter = () => {
    const currentPath = window.location.pathname;
    if (currentPath === '/wallpaperstudioprivacypolicy') {
      
      return null;      
    }
    return <Footer />;
  }
  return (
    <>
       {renderNavbar()}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/previewvideos/:id' element={<PreviewVideos />} />
        <Route path='/productdetails/:id' element={<ProductDetails />} />
        <Route path='/addtocart' element={<AddToCart />} />
        <Route path='/checkout' element={<CheckOut />} />
        <Route path='/login' element={<Login />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/invoice/:id' element={<Invoice />} />
        <Route path='/wallpaperstudioprivacypolicy' element={<WallPaperPrivacyPolicy />} />
        <Route path='/termsconditions' element={<TermsConditions />} />
        <Route path='/privacy' element={<PrivacyPolicy />} />
        <Route path='/licenses' element={<Licenses />} />
        <Route path='/success' element={<SuccessOrder/>} />
      </Routes>
      {renderFooter()}
     
    </>
  );
}

export default App;
