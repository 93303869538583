import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
// ... Other imports
const Invoice = () => {
    const [invoiceData, setInvoiceData] = useState(null);
    const { id } = useParams();
    
    const invoiceRef = useRef(null); 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_WEBPORTAL_URL}/api/printout/${id}`);
                const data = await response.json();
                if (data.ResponseCode === 1) {
                    setInvoiceData(data.ResponseData);
                } else {
                    console.error('Error fetching data:', data.ResponseText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [id]);
    const handlePrint = () => {
        if (invoiceRef.current) {
            window.print();
        }
    };
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };
    return (
        <>
            <section className="content">
                <Container fluid className='px-xxl-5 py-xxl-5' ref={invoiceRef}>
                    <div className="body_scroll">
                        <div className="block-header">
                            <div className="row">
                                <div className="col-xxl-12">
                                    <h2>Invoice</h2>
                                </div>
                            </div>
                        </div>
                        {invoiceData && (
                            <div style={{ backgroundColor: '#fff', padding: '20px' }}>
                                <div className="card">
                                    <div className="card-header">
                                        <strong></strong>
                                        <span className="float-right">
                                            <strong>Invoice No:</strong>{invoiceData.invoice_number}
                                        </span>
                                    </div>
                                    <div className="card-body">
                                        <div className="mb-4">
                                            <div className="d-flex  justify-content-between ">
                                                <div>Name:- {invoiceData?.user?.name || ''}</div>
                                                <div>Date:<span> {invoiceData?.orderdate
                                                    ? new Date(invoiceData.orderdate).toLocaleDateString('en-GB', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                    })
                                                    : ''}</span></div>
                                            </div>
                                            <div>Email: {invoiceData?.user?.email || ''}</div>
                                            <div>Order No:{invoiceData?.orderno || ''}</div>
                                            <div>Phone: {invoiceData?.user?.phone || ''}</div>
                                            <hr />
                                        </div>
                                        <div className="table-responsive-sm">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Template Image</th>
                                                        <th>Template Name</th>
                                                        <th className="right">Unit Cost</th>
                                                        <th className="center">Tax</th>
                                                        <th className="right">Sub Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="left"> <img src={invoiceData?.template?.image || ''} alt="" className='img-fluid w-25 ' /></td>
                                                        <td className="left">{invoiceData?.template?.name || ''}</td>
                                                        <td className="left">{invoiceData?.template?.price || ''}</td>
                                                        <td className="right">-</td>
                                                        <td className="center">{invoiceData?.template?.discount_price || ''}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row mt-0">
                                            <div className="col-lg-8 col-sm-5"></div>
                                            <div className="col-lg-4 col-sm-5 ml-auto">
                                                <table className="table table-light ">
                                                    <tbody>
                                                        <tr>
                                                            <td className="left">
                                                                <strong>Total</strong>
                                                            </td>
                                                            <td className="right">₹{invoiceData?.template?.price || ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="left">
                                                                <strong>Delivery Charges</strong>
                                                            </td>
                                                            <td className="right">₹ 0</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="left">
                                                                <strong>Tax ₹ (%)</strong>
                                                            </td>
                                                            <td className="right">₹ 0</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="left">
                                                                <strong>Discount ₹ (%)</strong>
                                                            </td>
                                                            <td className="right">₹ 0</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="left">
                                                                <strong>Total</strong>
                                                            </td>
                                                            <td className="right">
                                                                <strong>₹{invoiceData?.template?.discount_price || ''}</strong>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                            <button
                type="button"
                className="btn btnBottolgreen buttoninvoice mt-4"
                onClick={handlePrint}
            >
                Print Invoice
            </button>
                    </div>
                </Container>
            </section>
        </>
    );
}
export default Invoice;