import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import style from './DetailsPages.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { BiMap, BiPhone, BiEnvelope, BiLink } from 'react-icons/bi';

const DetailsPages = ({ template_id }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [templateData, setTemplateData] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    title: '',
    description: '',
    phone: '',
  });
  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = async (e, template_id) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_WEBPORTAL_URL}/api/contact`, {
        ...formData,
        template_id: template_id
      });
      const { ResponseCode, ResponseText, ResponseData } = response.data;
      if (ResponseCode === 0) {
        setErrorMessages(ResponseData.errors);
        setSuccessMessage('');
      } else {
        setErrorMessages([]);
        setSuccessMessage(ResponseText);
        setFormData({
          name: '',
          email: '',
          title: '',
          description: '',
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error.message);
    }
  };
  console.log(template_id);
  useEffect(() => {
    const fetchTemplateData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_WEBPORTAL_URL}/api/template-data/${id}`);
        setTemplateData(response.data.ResponseData);
        setSelectedImage(response.data.ResponseData.image);
      } catch (error) {
        console.error('Error fetching template data:', error.message);
      }
    };

    fetchTemplateData();
  }, [id]);

  const handleAddToCart = async () => {
    const userId = localStorage.getItem('userId');
    if (!userId) {

      navigate('/login');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_WEBPORTAL_URL}/api/cart-add`, {
        user_id: userId,
        template_id: id,
      });

      toast.success('Product added to cart successfully!');
      navigate('/addtocart');
    } catch (error) {
      console.error('Error adding to cart:', error.message);
      toast.error('Failed to add product to cart!');
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  if (!templateData) {

    return <p>Loading...</p>;
  }

  return (
    <>
      <section className={style.DetailsPages}>
        <Container fluid className='px-xxl-5 '>
          <div className='row'>
            <div className='col-12 col-lg-5' data-aos="fade-up" data-aos-duration="2000">
              <div>
                {selectedImage && <img src={selectedImage} alt="Selected Image" className={style.imgDetails} />}
              </div>
              <div className='d-flex mt-3  '>
                {templateData.subimages && templateData.subimages.map((subimages) => (
                  <img
                    key={subimages.id}
                    src={subimages.image}
                    alt={`Subimage ${subimages.id}`}
                    className={style.imgThumbnail}
                    onClick={() => handleImageClick(subimages.image)}
                  />
                ))}
              </div>
            </div>
            <div className='col-12  col-lg-6  d-flex flex-column productdetail  ' data-aos="fade-up" data-aos-duration="2000">
              <div>
                <span className='fw-bold ' style={{ fontSize: '30px' }}>{templateData.name}</span>
                <span className='text-bold d-flex flex-column  ms-2'>{templateData.short_description.slice(0, 1000)}...</span>
              </div>
              <hr />
              <div className='mt-xxl-3'>
                <span className='fw-bold fs-5'>${templateData.discount_price}<span className='text-decoration-line-through text-danger ms-2'>${templateData.price}</span></span>
              </div>
              <div className='mt-4'>
                <Button className='text-uppercase btnGreen' onClick={handleAddToCart}>Add to cart</Button>
                <Button className='text-uppercase btnGreen ms-3' onClick={() => { window.open(templateData.livepreview, '_blank') }}>Live Preview</Button>
              </div>
            </div>
          </div>
          <div className='mt-5'>
            {/* <h1 className=' fw-bold  mb-3 ' style={{ color: '#1377BF' }}>Template Discription</h1> */}
          </div>
          <div className='row'>
            <div className='col-11 '>
              <div className='htmlimg'>
                <div dangerouslySetInnerHTML={{ __html: templateData.desciption }} />
              </div>
            </div>

          </div>
        </Container>
      </section>
      <div className={style.popularFlex}>
        <h2 className={style.headPopular}>Contact Us</h2>
      </div>
      <Container>
        <div className='row d-flex'>
          <div className='col-6'>
            <Form onSubmit={(e) => handleSubmit(e, template_id)} validate>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  style={{ border: '1px solid #00A0C8', borderRadius: '5px' }}
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <div className='row'>
                <div className='col'>
                  <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      style={{ border: '1px solid #00A0C8', borderRadius: '5px' }}
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </div>
                <div className='col'>
                  <Form.Group className="mb-3" controlId="formPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type='tel'
                      pattern="[0-9]{10}"
                      placeholder="Enter your phone number"
                      name="phone"
                      maxLength={10}
                      minLength={10}
                      style={{ border: '1px solid #00A0C8', borderRadius: '5px' }}
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </div>
              </div>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your Title name"
                  style={{ border: '1px solid #00A0C8', borderRadius: '5px' }}
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formMessage">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter your discription"
                  style={{ border: '1px solid #00A0C8', borderRadius: '5px' }}
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Button className='btnGreen px-5' type="submit">
                Submit
              </Button>
            </Form>
          </div>
          <div className='col-6 mt-3'>
            <h3 className='ms-5 mb-3' style={{ color: '#1377BF', textAlign: 'left' }} >Contact Info</h3>
            <div className='ms-5 d-flex flex-column gap-2'>
              <div className="mb-3 ">
                <BiMap />
                <span className="ms-2 ">123 Street, City, Country</span>
              </div>
              <div className="mb-3">
                <BiPhone />
                <span className="ms-2">+123 456 7890</span>
              </div>
              <div className="mb-3">
                <BiEnvelope />
                <span className="ms-2">example@example.com</span>
              </div>
              <div className="mb-3">
                <BiLink />
                <span className="ms-2">www.example.com</span>
              </div>
            </div>

          </div>
        </div>
      </Container>
      <ToastContainer />
    </>
  );
};

export default DetailsPages;
