import React , { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import banner1 from '../../../../Images/banner-1.jpg';
import banner2 from '../../../../Images/banner-2.jpg';
import { useNavigate } from 'react-router-dom';
const Banner = () => {
  const [bannerData, setBannerData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get( `${process.env.REACT_APP_WEBPORTAL_URL}/api/home`); // Replace with your API endpoint
        const data = response.data;

        if (data.ResponseCode === 1) {
          setBannerData(data.ResponseData.banner);
        } else {
          console.error('Failed to fetch banner data');
        }
      } catch (error) {
        console.error('Error fetching banner data:', error);
      }
    };

    fetchData();
  }, []);
 
  return (
    <>
    <section>
      <div>
        <Carousel>
          {bannerData.map((banner) => (
            <Carousel.Item key={banner.id}>
              <img src={banner.image} alt={banner.name}
               className='banner'           
               onClick={() => navigate(`/productdetails/${banner.link_id}`)} 
                />
              <Carousel.Caption>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  </>
  )
}

export default Banner
