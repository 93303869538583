import React from 'react'

const Topbar = () => {
  return (
   <> 
   <h1 style={{color:"red", height:"60px", textAlign:"center",backgroundColor:"pink"}}>topbar</h1>
   </>
  )
}

export default Topbar
