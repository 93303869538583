import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import style from '../AddToCart/CheckOut/CheckOut.module.css'
import { Container } from 'react-bootstrap';
const SuccessOrder = () => {
    const [apiData, setApiData] = useState([]);
    const userId = localStorage.getItem('userId');
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_WEBPORTAL_URL}/api/orderuserwiselist/${userId}`);
                setApiData(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        };
        fetchData();
    }, []);
    console.log(apiData);
    const handleGenerateInvoice = (orderId) => {
        window.location.href = `/invoice/${orderId}`;
    };
    const [downloadData, setDownloadData] = useState(null);
    const handleDownload = async (template_id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_WEBPORTAL_URL}/api/download-add`, {
                user_id: userId,
                template_id: template_id,
            });
            setDownloadData(response.data.ResponseData);
        } catch (error) {
            console.error('Error initiating download:', error);
        }
    };
    useEffect(() => {
        if (downloadData) {
           
            console.log('Download initiated successfully', downloadData);            
            const downloadLink = document.createElement('a');
            downloadLink.href = `${downloadData.folder}/yourFolder.zip`; 
            downloadLink.target = '_blank';
            downloadLink.download = 'yourFolder.zip'; 
            downloadLink.click();
        }
    }, [downloadData]);
    return (
        <>
            <section>
                <Container className='mt-5'>
                    <div className="panel-title mb-3">Purchase Order List</div>
                    <div className={style.CheckOutt}>
                        <h5 className='text-uppercase fw-bold '>Your Order</h5>
                    <table className="table table-borderless">
                    <tbody >
                            {apiData.map((item) => (
                                <tr >
                                    <td >
                                        <div className='d-flex align-items-center justify-content-between gap-3 mt-5 mb-5'>
                                            <div className='d-flex align-items-center justify-content-between  gap-5'>
                                                <img
                                                    className='img-fluid'
                                                    style={{ height: "100px", width: "" }}
                                                    decoding="async"
                                                    src={item.template.image}
                                                />
                                                <a className='fs-5 text-black ultimate'>
                                                    {item.template.name}
                                                     </a>
                                                <span className='text-black fw-bold'><span className='text-danger fw-bold text-decoration-line-through me-2'>{item.template.price}</span> {item.template.discount_price}</span>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end gap-3'>
                                            <button className='btnBottolgreen px-4 py-2' onClick={() => handleDownload(item.template_id)} >Download Now</button>
                                            <div className="ms-auto p-2 justify-content-end">
                                            <Link
                                                to={`/invoice/${item.id}`}
                                                className="btnBottolgreen px-4 py-2"
                                                style={{ backgroundColor: "#014C8D", borderColor: "#014C8D" }}
                                                onClick={() => handleGenerateInvoice(item.id)}
                                            >
                                                <i className="bi bi-download pe-2"></i>
                                                Generate Invoice
                                            </Link>
                                            </div>
                                        </div>
                                        <hr />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </Container>
            </section>
        </>
    )
}
export default SuccessOrder