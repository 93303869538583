import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import style from '../AddToCart/CheckOut/CheckOut.module.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const Profile = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [cartData, setCartData] = useState(0);
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        phone: '',
        address: ''
    });
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        if (!userId) {
            // If user is not logged in, redirect to login page
            navigate('/login');
        } else {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_WEBPORTAL_URL}/api/download-list/${userId}`);
                    const responseData = await response.json();
                    setData(responseData.ResponseData);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchData();
        }
    }, [userId, navigate]);

    // ---Purchase Order--- //
    const [apiData, setApiData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_WEBPORTAL_URL}/api/orderuserwiselist/${userId}`);
                setApiData(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        };
        fetchData();
    }, []);

    // ---Download--- //
    const [downloadData, setDownloadData] = useState(null);
    const handleDownload = (folderUrl) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = folderUrl;
        downloadLink.target = '_blank';
        downloadLink.download = 'yourFolder.zip';
        downloadLink.click();
    };


    useEffect(() => {
        if (downloadData) {

            console.log('Download initiated successfully', downloadData);
            const downloadLink = document.createElement('a');
            downloadLink.href = `${downloadData.folder}/yourFolder.zip`;
            downloadLink.target = '_blank';
            downloadLink.download = 'yourFolder.zip';
            downloadLink.click();
        }
    }, [downloadData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_WEBPORTAL_URL}/api/userdata/${userId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
                const responseData = await response.json();
                setFormData(responseData.ResponseData);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchData();
    }, [userId]);
    console.log(data);
    // console.log("folder",downloadData.folder);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_WEBPORTAL_URL}/api/updateuser/${userId}`, formData);
            if (response.data.ResponseCode === 1) {
                toast.success('Profile updated successfully');
            } else {
                console.error('Failed to update profile');
            }
        } catch (error) {
            console.error('Error updating profile:', error);

        }
    };
    const handleGenerateInvoice = (orderId) => {
        window.location.href = `/invoice/${orderId}`;
    };
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleLogout = () => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('phoneNumber');
        setCartData([]);
        navigate('/');
    };
    return (
        <>
            <section className='mt-50'>
                <Container>
                    <div className="row">
                        <div className='col-12 d-flex gap-5 mt-5'>
                            <input className="radio" id="one" name="group" type="radio" defaultChecked />
                            <input className="radio" id="two" name="group" type="radio" />
                            <input className="radio" id="three" name="group" type="radio" />
                            <input className="radio" id="four" name="group" type="radio" />
                            <div className="tabs d-flex flex-column gap-3 " >
                                <label className="tab mt-5" id="one-tab" htmlFor="one">My Profile</label>
                                <label className="tab" id="two-tab" htmlFor="two">Purchase</label>
                                <label className="tab" id="three-tab" htmlFor="three">Download</label>
                                <label className="tab" id="four-tab" htmlFor="four">Log Out</label>
                            </div>
                            <div className="panels">
                                <div className="panel" id="one-panel">
                                    <div className="panel-title mb-3">My Profile</div>
                                    <form onSubmit={handleSubmit} validate>
                                        <div className="d-flex flex-column gap-2">
                                            <label htmlFor="full_name">Full Name</label>
                                            <input type="text" id="full_name" name="full_name" value={formData.full_name} onChange={handleChange} required className='w-75 px-2 py-1 searchInput border border-1 rounded' />
                                            <label htmlFor="email">Email</label>
                                            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required className='w-75 px-2 py-1 searchInput border border-1 rounded' />
                                            <label htmlFor="phone">Phone</label>
                                            <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} required className='w-75 px-2 py-1 searchInput border border-1 rounded' />
                                            <label htmlFor="address">Address</label>
                                            <textarea id="address" name="address" value={formData.address} onChange={handleChange} required className='w-75 px-2 py-1 searchInput border border-1 rounded' />
                                            <input type="submit" value="Update" className='mt-5 mb-3 btnBottolgreen py-2 w-25' />
                                        </div>
                                    </form>
                                </div>
                                <div className="panel" id="two-panel">
                                    <div className="panel-title mb-3">Purchase Order</div>
                                    <div className={style.CheckOutt}>
                                        <h5 className='text-uppercase fw-bold '>Your Order</h5>
                                        <tbody >
                                            {apiData.map((item) => (
                                                <tr >
                                                    <td >
                                                        <div className='d-flex align-items-center justify-content-between gap-3 mt-5 mb-5'>
                                                            <div className='d-flex align-items-center justify-content-between  gap-5'>
                                                                <img
                                                                    className='img-fluid'
                                                                    style={{ height: "100px", width: "" }}
                                                                    decoding="async"
                                                                    src={item.template.image}
                                                                />
                                                                <a className='fs-5 text-black ultimate'>
                                                                    {item.template.name}</a>
                                                                <span className='text-black fw-bold'><span className='text-danger fw-bold text-decoration-line-through me-2'>$149</span>$99</span>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-end gap-3'>
                                                            <button className='btnBottolgreen px-4 py-2' onClick={() => handleDownload(item.template.folder)}>
                                                                Download Now
                                                            </button>
                                                            <div className="ms-auto p-2 justify-content-end">
                                                                <Link
                                                                    to={`/invoice/${item.id}`}
                                                                    className="btnBottolgreen px-4 py-2"
                                                                    style={{ backgroundColor: "#014C8D", borderColor: "#014C8D" }}
                                                                    onClick={() => handleGenerateInvoice(item.id)}
                                                                >
                                                                    <i className="bi bi-download pe-2"></i>
                                                                    Generate Invoice
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </div>
                                </div>
                                <div className="panel" id="three-panel">
                                    <div className="panel-title">Download Now</div>
                                    <p>You haven't Download anything yet. </p>
                                    {data.map(item => (
                                        <div>
                                            <div className='d-flex align-items-center justify-content-between  gap-3'>
                                                <img
                                                    className='img-fluid'
                                                    style={{ height: "100px", width: "" }}
                                                    decoding="async"
                                                    src={item.template.image}
                                                    alt={item.template.name}
                                                />
                                                <a className='fs-5 text-black ultimate'>
                                                    {item.template.name}</a>
                                                <span className='text-black fw-bold'><span className='text-danger fw-bold text-decoration-line-through me-2'>$ ${item.template.price}</span>${item.template.discount_price}</span>
                                            </div>
                                            <hr />
                                        </div>
                                    ))}
                                    {/* <button className='btnBottolgreen px-4 py-2' onClick={() => navigate('/')}>Download Now</button> */}
                                </div>
                                <div className="panel" id="four-panel">
                                    <div className="panel-title mb-3"  >Log Out</div>
                                    <p>Are you sure you want to <a href="" className='text-danger fw-bold' onClick={handleLogout}>Log Out?</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <ToastContainer />
            </section>
        </>
    )
}

export default Profile
