import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import SingOut from '../SingOut/SingOut';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    device_token: 'olkjkfdf',
    device_type: 'web',
    login_type: 'email',
    email: '',
    password: '',
    userId: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6; 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { email, password } = formData;
  
    if (!email.trim()) {
      toast.error('Please enter your email.');
      return;
    }
  
    if (!validateEmail(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }
  
    if (!password.trim()) {
      toast.error('Please enter your password.');
      return;
    }
  
    if (!validatePassword(password)) {
      toast.error('Password must be at least 6 characters long.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_WEBPORTAL_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      console.log(data);
      if (data.ResponseCode === 1) {
  
        localStorage.setItem('userId', data.ResponseData.user.id);
        navigate('/');
        toast.success('Login successful!');
      } else {
        toast.error('Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
    return (
    <>
      <section className='container'>
        <div className='mt-50'>
          <div className='row'>
            <div className='col-12'>
              <div className="login-wrap">
                <div className="login-html">
                  <input id="tab-1" type="radio" name="tab" className="sign-in" defaultChecked /><label htmlFor="tab-1" className="tab text-white">Sign In</label>
                  <input id="tab-2" type="radio" name="tab" className="sign-up" /><label htmlFor="tab-2" className="tab text-white">Sign Up</label>
                  <div className="login-form">
                    <div className="sign-in-htm">
                      <div className="group">
                        <label htmlFor="email" className="label">Email</label>
                        <input
                          id="email"
                          type="text"
                          className="input mt-2"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="group">
                        <label htmlFor="password" className="label">Password</label>
                        <input
                          id="password"
                          type="password"
                          className="input mt-2"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="group">
                        <input id="check" type="checkbox" className="check" defaultChecked />
                        <label htmlFor="check"><span className="icon" /> Keep me Signed in</label>
                      </div>
                      <div className="group">
                        <Button type="submit" className="button" onClick={handleSubmit}>Submit</Button>
                      </div>
                      <div className="hr" />
                      <div className="foot-lnk ">
                        <a href="#forgot">Forgot Password?</a>
                      </div>
                    </div>
                    <SingOut />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  )
}

export default Login;
