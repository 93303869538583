import React, { useEffect, useState } from 'react'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';

const AddToCart = () => {
  const navigate = useNavigate()
  const [cartItems, setCartItems] = useState([]);
  const userId = localStorage.getItem('userId');
  const [errorMessage, setErrorMessage] = useState('');



  useEffect(() => {
    if (!userId) {

      navigate('/login');
    } else {
      const fetchCartData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_WEBPORTAL_URL}/api/cart-list/${userId}`);
          setCartItems(response.data.ResponseData);
        } catch (error) {
          console.error('Error fetching cart data:', error.message);
        }
      };

      fetchCartData();
    }
  }, [navigate, userId]);
  const handleProceedToCheckout = () => {
    if (cartItems.length === 1) {

      navigate('/checkout');
    } else {

      setErrorMessage('Please select only one template');
    }
  };
  const handleRemoveFromCart = async (cartItemId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_WEBPORTAL_URL}/api/cart-delete/${cartItemId}`);
      console.log('Cart item deleted successfully:', response.data);
      toast.success('Product removed from cart successfully!');

      const updatedCart = cartItems.filter(item => item.id !== cartItemId);
      setCartItems(updatedCart);
    } catch (error) {
      console.error('Error deleting from cart:', error.message);
      toast.error('Failed to remove product from cart!');
    }
  };
  return (
    <>
      <section>
        <Container fluid className='px-xxl-5'>
          <div className='row d-flex cartmargin' style={{ marginTop: '100px' }}>
            <div className='col-12  col-md-12 col-lg-8 col-xl-8 col-xxl-7' data-aos='fade-up' data-aos-duration='2000'>
              <div>
                <h1
                  className='mb-2 Item'
                  style={{ fontWeight: 'bold', fontSize: '23px', letterSpacing: '1px', textTransform: 'uppercase' }}
                >
                  You Have {cartItems.length} Item In Your Cart
                </h1>
                <hr />
                <table class='table'>
                  <thead>
                    <tr class='fs-5'>
                      <th scope='col' style={{ color: '#5E5E5E' }}>
                        Product
                      </th>
                      <th scope='col' style={{ color: '#5E5E5E' }}>
                        Price
                      </th>
                      <th scope='col' style={{ color: '#5E5E5E' }}>
                        Subtotal
                      </th>

                      <th scope='col'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((cartItem) => (
                      <tr key={cartItem.id}>
                        <td>
                          <div className='d-flex gap-3 mt-5 mb-5'>
                            <div>
                              <span>
                                <a>
                                  <img
                                    className='img-fluid'
                                    style={{ height: '100px', width: '' }}
                                    decoding='async'
                                    src={cartItem.template.image}
                                  />
                                </a>{' '}
                              </span>
                            </div>
                            <div>
                              <a className='fs-5 text-black ultimate'>{cartItem.template.name}</a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='mt-5'>
                            <span className='fs-5 text-black '>${cartItem.template.price}</span>
                          </div>
                        </td>
                        <td>
                          <div className='mt-5'>
                            <span className='fs-5 text-black'>${cartItem.template.discount_price}</span>
                          </div>
                        </td>
                        <td>
                          <div className='mt-5'>
                            <Button className='fs-5 text-black btnGreen' onClick={() => handleRemoveFromCart(cartItem.id)}>Remove</Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div>
                  <div className='mt-5'>
                    <Button className='btnGreen px-5 py-3 fs-5 ' onClick={() => navigate('/')}>
                      <FontAwesomeIcon icon={faArrowRight} className='me-3' />
                      Continue Shopping
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12  col-md-12 col-lg-4 col-xl-4 col-xxl-4' data-aos='fade-up' data-aos-duration='2000'>
              <div
                className='w-100'
                style={{ width: '50%', border: '1px solid #f0f0f0', padding: '30px', marginTop: '20px' }}
              >
                <h5 className='text-uppercase fw-bold'>Cart totals</h5>
                <hr style={{ marginTop: '20px', marginBottom: '35px' }} />
                <hr />
                <div className='d-flex justify-content-between'>
                  <span className='fw-bold' style={{ color: '#5E5E5E', fontSize: '20px' }}>
                    Total
                  </span>
                  <span className='fw-bold' style={{ color: '#00a0c8', fontSize: '20px' }}>
                    ${cartItems.reduce((total, item) => total + item.template.discount_price, 0)}
                  </span>
                </div>
                <hr />
                <div className='d-flex align-items-end mt-5 mb-3 flex-column gap-3'>
                  <div>
                    <Button className='btnGreen'>Update cart</Button>
                  </div>
                  <div>
                    {errorMessage && (
                      <p className="text-danger">{errorMessage}</p>
                    )}
                    <Button className='btnBottolgreen' onClick={handleProceedToCheckout}>
                      Proceed to checkout
                    </Button>                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      < ToastContainer />
    </>
  );
};

export default AddToCart
