import React, { useEffect, useState } from 'react';
import axios from 'axios';
import style from './VideoTemplates.module.css';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';


const VideoTemplates = () => {
  const [categories, setCategories] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { id: menuId } = useParams();
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_WEBPORTAL_URL}/api/category/${menuId}`);
        setCategories(response.data.ResponseData);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, [menuId]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiUrl = '';
        if (selectedCategory === null) {
          apiUrl = `${process.env.REACT_APP_WEBPORTAL_URL}/api/menupages/${menuId}`;
        } else {
          apiUrl = `${process.env.REACT_APP_WEBPORTAL_URL}/api/categoryfilter/${selectedCategory}`;
        }
        const response = await axios.get(apiUrl);
        setTemplateData(response.data.ResponseData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [selectedCategory, menuId]);
  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };
  // Function to handle changes in the search input
  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };
  // Filter templateData based on the search term
  const filteredTemplateData = templateData.filter((template) =>
    template.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>

      <section>
      <div className={style.HelpFlex}>
          <h1 className='text-white fw-bold ' >How Can We Help You?</h1>
          <input
            type="text"
            placeholder=" Search..."
            className='form-control align-items-center w-25 py-2'
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
        </div>
        <Container fluid className='px-xxl-5'>
          <div className='row'>
            <div className='col-12 col-md-4 col-lg-3 col-xl-3' data-aos="fade-right" data-aos-duration="2000">
              <ul className='list-unstyled mt-5 d-flex gap-4 flex-column'>
                <li
                  key="all"
                  className={`fw-bold fs-5 p-3 border ${selectedCategory === null ? 'active' : ''}`}
                  style={{ backgroundColor: selectedCategory === null ? '#03A9F4' : 'inherit' }}
                  onClick={() => handleCategoryClick(null)}
                >
                  <a className='text-black pointer'>All Templates</a>
                </li>
                {categories.map(category => (
                  <li
                    key={category.id}
                    className={`fw-bold fs-5 p-3 border ${selectedCategory === category.id ? 'active' : ''}`}
                    onClick={() => handleCategoryClick(category.id)}
                    style={{ backgroundColor: selectedCategory === category.id ? '#03A9F4' : 'inherit' }}
                  >
                    <a className='text-black pointer'>{category.name}</a>
                  </li>
                ))}
              </ul>

            </div>
            <div className='col-12 col-md-8 col-lg-9 col-xl-9 mt-5' data-aos="fade-up" data-aos-duration="2000">
              <h4 style={{ fontWeight: 'bold', fontSize: '24px', color: '#67c100' }} className='mb-4'>
                {selectedCategory && categories.find(cat => cat.id === selectedCategory) ? `${categories.find(cat => cat.id === selectedCategory).name} Templates` : 'All Templates'}
              </h4>
              <div className='row'>
              {filteredTemplateData.map(template => (
                  <div key={template.id} className='col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex mb-5'>
                    <div className={style.TemplatesFlex}>
                      <div onClick={() => navigate(`/productdetails/${template.id}`)} className={style.videoImage}>
                        <img src={template.image} alt="" className={style.videoImageFlex} />
                      </div>
                      <div className='p-4'>
                        <a
                          href={template.livepreview}
                          className='text-black fw-bold fs-5 pointer'
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => navigate(`/productdetails/${template.id}`)}
                        >
                          {template.name}
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default VideoTemplates;
