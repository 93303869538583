import React from 'react'
import Topbar from '../../Layout/Topbar/Topbar'
import AdvertiseBanner from './AdvertiseBanner/AdvertiseBanner'
import PopularprojectTemplate from './PopularprojectTemplate/PopularprojectTemplate'
import PopularTemplatePacks from './PopularTemplatePacks/PopularTemplatePacks'
import Banner from './Banner/Banner'
import ProfessoinalProjectpacks from './ProfessoinalProjectpacks/ProfessoinalProjectpacks'
import OtherUsefulTemplates from './OtherUsefulTemplates/OtherUsefulTemplates'
import EssentialProjectManagement from './EssentialProjectManagement/EssentialProjectManagement'
import VBAProjects from './VBAProjects/VBAProjects'
import Footer from '../../Layout/Footer/Footer'


const Home = () => {
  return (
  <>
  <Banner/>
  <PopularprojectTemplate/>
  </>
  )
}

export default Home
