import React from 'react'
import DetailsPages from './DetailsPages/DetailsPages'

const ProductDetails = () => {
  return (
  <>
  <DetailsPages/>
  </>
  )
}

export default ProductDetails
