import React, { useState, useEffect } from 'react';
import style from './CheckOut.module.css';
import { Button, Container } from 'react-bootstrap';
import product from '../../../../Images/project-1.png';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { ToastContainer, toast } from 'react-toastify';

const CheckOut = () => {
    const [stripe, setStripe] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        country: '',
        postcode: '',
    });
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        const fetchCartData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_WEBPORTAL_URL}/api/cart-list/${userId}`);
                setCartItems(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching cart data:', error.message);
            }
        };
        fetchCartData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_WEBPORTAL_URL}/api/userdata/${userId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
                const responseData = await response.json();
                setFormData(responseData.ResponseData);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchData();
    }, [userId]);
    console.log(formData);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_WEBPORTAL_URL}/api/updateuser/${userId}`, formData);
            if (response.data.ResponseCode === 1) {
                toast.success('Profile updated successfully');
            } else {
                console.error('Failed to update profile');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        if (cartItems && !stripe) {
            const fetchStripeKey = async () => {
                const stripeKey = await loadStripe("pk_test_51NQUg9SCaQ7PuGVBDt4QZvS1g7p7PlCYD3oAwvHJ4MufKl25CCQOhqKmDnQWlEtMABq2mQHGP0DyYAH5mNarqVjm00QocFlbxg");
                setStripe(stripeKey);
            };

            fetchStripeKey();
        }
    }, [cartItems, stripe]);

    const makePayment = async () => {
        // Check if any of the form fields are empty
        if (!formData.full_name ||
            !formData.email ||
            !formData.phone ||
            !formData.address ||
            !formData.city ||
            !formData.country ||
            !formData.postcode) {
            toast.error('Please fill in all the required fields before making a payment.');
            return;
        }

        if (!stripe || !cartItems) {
            return;
        }

        try {
            // Proceed with payment process
            const body = {
                products: cartItems.map((item) => ({
                    template: {
                        id: item.template.id,
                        name: item.template.name,
                        image: item.template.image,
                        price: item.template.price * 100, // Convert price to cents
                        discount_price: item.template.discount_price * 100, // Make sure the price is in cents
                    },
                })),
                user_id: userId,
            };

            const headers = {
                "Content-Type": "application/json"
            };

            const apiUrl = `${process.env.REACT_APP_WEBPORTAL_URL}/api/createCheckoutSession`;

            const response = await axios.post(apiUrl, JSON.stringify(body), { headers });
            const session = response.data;
            const result = await stripe.redirectToCheckout({
                sessionId: session.session_id
            });
            if (result.error) {
                console.error(result.error);
            }
        } catch (error) {
            console.error("Error during checkout:", error);
        }
    };




    if (!cartItems) {
        return <div>Loading...</div>;
    }

    const handleRemoveFromCart = async (cartItemId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_WEBPORTAL_URL}/api/cart-delete/${cartItemId}`);
            console.log('Cart item deleted successfully:', response.data);
            // Refresh cart items after deletion
            const updatedCart = cartItems.filter(item => item.id !== cartItemId);
            setCartItems(updatedCart);
        } catch (error) {
            console.error('Error deleting from cart:', error.message);
        }
    };
    return (
        <>
            <section className='mt-50'>
                <Container fluid className='px-xxl-5'>
                    <div className='row'>
                        <div className='col-12 col-md-6 col-xxl-5' data-aos="fade-up" data-aos-duration="2000">
                            <div className={style.CheckOut}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h5 className='text-uppercase fw-bold '>Billing Details</h5>
                                    {/* <button className='btnGreen px-4 py-1'>Edit</button> */}
                                </div>
                                <hr />
                                <form className='d-flex  flex-column gap-2 from-group' validate onSubmit={handleSubmit} >
                                    <label htmlFor="full_name">Full Name</label>
                                    <input type="text" value={formData.full_name} onChange={handleChange} name="full_name" className='p-2 ' style={{border: '1px solid #1D99E2', borderRadius: '5px'}} required />
                                    <label htmlFor="email">Email</label>
                                    <input type="email" value={formData.email} onChange={handleChange} name="email" className='p-2' style={{border: '1px solid #1D99E2', borderRadius: '5px'}} required />
                                    <label htmlFor="phone">Phone</label>
                                    <input type="tel" value={formData.phone} onChange={handleChange} name="phone" className='p-2' style={{border: '1px solid #1D99E2', borderRadius: '5px'}} required />
                                    <label htmlFor="address">Address</label>
                                    <input type="text" value={formData.address} onChange={handleChange} name="address" className='p-2' style={{border: '1px solid #1D99E2', borderRadius: '5px'}} required />
                                    <label htmlFor="city">City</label>
                                    <input type="text" value={formData.city} onChange={handleChange} name="city" className='p-2' style={{border: '1px solid #1D99E2', borderRadius: '5px'}} required />
                                    <label htmlFor="country">Country</label>
                                    <input type="text" value={formData.country} onChange={handleChange} name="country" className='p-2' style={{border: '1px solid #1D99E2', borderRadius: '5px'}} required />
                                    <label htmlFor="postcode">Post Code</label>
                                    <input type="text" value={formData.postcode} onChange={handleChange} name="postcode" className='p-2' style={{border: '1px solid #1D99E2', borderRadius: '5px'}} required />
                                    <div>
                                        <button className='btnGreen px-3 py-2 w-100 mt-5' onClick={makePayment} >Save & Payment</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-xxl-7' data-aos="fade-up" data-aos-duration="2000">
                            <div className={style.CheckOutt}>
                                <h5 className='text-uppercase fw-bold '>Your Order</h5>
                                <hr />
                              
                                {/* {cartItems.map((cartItem) => (
                                    <div key={cartItem.id}>
                                        <div className='d-flex  align-items-center'>
                                            <img src={cartItem.template.image} alt="" className='img-fluid w-25' />
                                            <span>{cartItem.template.name}</span>
                                            <span>${cartItem.template.discount_price} <span className='text-danger fw-bold text-decoration-line-through'>${cartItem.template.price}</span></span>
                                            <button className='btnGreen px-3 py-2' onClick={() => handleRemoveFromCart(cartItem.id)}>Remove</button>
                                        </div>
                                        <hr />
                                    </div>
                                ))} */}

                                <table class='table'>
                                    <thead>
                                        <tr class='fs-5'>
                                            <th scope='col' style={{ color: '#5E5E5E' }}>
                                                Product
                                            </th>
                                            <th scope='col' style={{ color: '#5E5E5E' }}>
                                                Price
                                            </th>
                                            <th scope='col' style={{ color: '#5E5E5E' }}>
                                                Subtotal
                                            </th>

                                            <th scope='col'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartItems.map((cartItem) => (
                                            <tr key={cartItem.id}>
                                                <td>
                                                    <div className='d-flex gap-3 mt-5 mb-5'>
                                                        <div>
                                                            <span>
                                                                <a>
                                                                    <img
                                                                        className='img-fluid'
                                                                        style={{ height: '100px', width: '' }}
                                                                        decoding='async'
                                                                        src={cartItem.template.image}
                                                                    />
                                                                </a>{' '}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <a className='fs-5 text-black ultimate'>{cartItem.template.name}</a>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='mt-5'>
                                                        <span className='fs-5 text-black '>${cartItem.template.price}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='mt-5'>
                                                        <span className='fs-5 text-black'>${cartItem.template.discount_price}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='mt-5'>
                                                        <Button className='fs-5 text-black btnGreen' onClick={() => handleRemoveFromCart(cartItem.id)}>Remove</Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                              
                                <div className='d-flex justify-content-between'>
                                    <span>Total</span>
                                    <span className='fw-bold'>${cartItems.reduce((total, item) => total + item.template.discount_price, 0)}</span>
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default CheckOut;
