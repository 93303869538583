import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import axios from 'axios';
import style from '../TermsConditions/TermsCondition.module.css'

const PrivacyPolicy = () => {
    const [privacyData, setprivacyData] = useState({});

    useEffect(() => {
        const fetchprivacyData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_WEBPORTAL_URL}/api/privacypolicy`);
                setprivacyData(response.data.ResponseData[0]);
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        };

        fetchprivacyData();
    }, []);
    return (
        <>
            <div className='mt-50'>
                <div className={style.popularFlex} >
                    <h2 className={style.headPopular}>privacy policy</h2>
                </div>
            </div>
            <Container>
                <div className="App mt-5 mb-5">
                    <div className="separator">
                        <div
                            className="text-left "
                            style={{ fontSize: "16px", lineHeight: "1.6", color: "black", textAlign: "justify" }}
                            dangerouslySetInnerHTML={{ __html: privacyData.description }}
                        />
                    </div>
                </div>
            </Container>
        </>
    )
}

export default PrivacyPolicy