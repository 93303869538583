import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';


const SignUp = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: '',
    device_token: 'olkjkfdf',
    device_type: 'web',
    login_type: 'email',
    email: '',
    phone: '',
    password: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = `${process.env.REACT_APP_WEBPORTAL_URL}/api/sign-up`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({        
          ...formData,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('Registration successful!');
        navigate('/login');
        
        document.getElementById('tab-1').checked = true;
      } else {
        console.error('API error:', data);
        if (data.ResponseCode === '0' && data.ResponseText === 'validation fail') {
          toast.error('The email has already been taken. Please use a different email.');
        } else if (data.ResponseCode === '0' && data.ResponseText === 'phone number already exists') {
          toast.error('This phone number is already registered.');
        } else {
          toast.error('Registration failed. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error during API call:', error);
      toast.error('Registration failed. Please try again.');
    }
  };
console.log(formData);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit} >
      <div className="sign-up-htm">
        <div className="group">
          <label htmlFor="first_name" className="label">First Name</label>
          <input
            id="first_name"
            type="text"
            className="input mt-2"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="group">
          <label htmlFor="email" className="label">Email Address</label>
          <input
            id="email"
            type="text"
            className="input mt-2"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="group">
          <label htmlFor="phone" className="label">Phone Number</label>
          <input
            id="phone"
            className="input mt-2"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="group">
          <label htmlFor="password" className="label">Password</label>
          <input
            id="password"
            type="password"
            className="input mt-2"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="group">
          <input type="submit" className="button" value="Sign Up"  htmlFor="tab-1" />
        </div>
        <div className="hr" />
        <div className="foot-lnk">
          <label htmlFor="tab-1" className='pointer' >Already Member?</label>
        </div>
      </div>
    </form>
  );
};

export default SignUp;
